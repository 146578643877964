import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'shared/src/utils';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { UsersStore } from '@/modules/users/stores/users-store';
import { UsersPagination } from '@/modules/users/components/users-content/users-pagination';
import { UsersFilters } from '@/modules/users/components/users-content/users-filters';
import { UsersTable } from '@/modules/users/components/users-content/users-table';
import Lottie from 'react-lottie';
import animationData from '@/assets/lotties/table-loader.json';
import * as React from 'react';

export const UsersPage = provide([UsersStore])(
    observer(() => {
        const [{ init }] = useDependencies(UsersStore);

        useEffect(() => {
            init();
        }, []);

        return (
            <Box
                sx={{
                    maxWidth: 'var(--Content-maxWidth)',
                    m: 'var(--Content-margin)',
                    p: 'var(--Content-padding)',
                    width: 'var(--Content-width)',
                }}
            >
                <Card>
                    <Typography variant={'h5'} p={3}>
                        Users
                    </Typography>
                    <UsersFilters />
                    <Divider />
                    <UsersTable />
                    <Divider />
                    <UsersPagination />
                </Card>
            </Box>
        );
    })
);
