import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { FC, PropsWithChildren } from 'react';

interface EditFormItemProps {
    name: string;
    required?: boolean;
}

export const EditFormItem: FC<PropsWithChildren<EditFormItemProps>> = ({
    name,
    required,
    children,
}) => {
    return (
        <Stack direction="row" p={2} alignItems={'center'}>
            <Box flex={'1 0 30%'}>
                <InputLabel required={required}>{name}</InputLabel>
            </Box>
            <Box flex={'1 0 70%'}>{children}</Box>
        </Stack>
    );
};
