import type { Settings } from '@/types/settings';
import { config } from '@/config';

export function applyDefaultSettings(settings: Partial<Settings>): Settings {
    return {
        colorScheme: config.site.colorScheme,
        primaryColor: config.site.primaryColor,
        direction: 'ltr',
        navColor: 'discrete',
        layout: 'horizontal',
        language: config.site.language,
        ...settings,
    };
}
