import {
    FC,
    PropsWithChildren,
    useState,
    useContext,
    useEffect,
    useMemo,
} from 'react';

import {Container} from 'inversify';

import {ContainerContext, Entry} from './common';

type ProviderProps = PropsWithChildren<{
    entries: Entry<any>[];
}>;

export const Provider: FC<ProviderProps> = (
    {
        entries,
        children,
    }
) => {
    const [isReady, setIsReady] = useState(false);
    const parentContainer = useContext(ContainerContext);

    const container = useMemo(() => new Container(), []);

    useEffect(() => {
        container.parent = parentContainer;

        entries.forEach(entry => {
            container.bind(entry).to(entry).inSingletonScope();
        })

        setIsReady(true);

        return () => {
            entries.forEach(entry => {
                container.unbind(entry);
            });
        };
    }, []);

    if (!isReady) {
        return null;
    }

    return (
        <ContainerContext.Provider value={container}>
            {children}
        </ContainerContext.Provider>
    );
};