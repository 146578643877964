import { FC, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Lottie from 'react-lottie';
import animationData from '@/assets/lotties/table-loader.json';

export const TableLoadingWrapper: FC<
    PropsWithChildren<{ isLoading?: boolean }>
> = ({ children, isLoading }) => {
    return (
        <Box
            sx={{
                overflowX: isLoading ? 'hidden' : 'auto',
                position: 'relative',
            }}
        >
            {children}
            {!!isLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%',
                        width: '100%',
                        zIndex: 10000,
                        background: 'rgba(255, 255, 255, 0.7)',
                    }}
                    display={'flex'}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice',
                            },
                        }}
                        height={200}
                        width={200}
                    />
                </Box>
            )}
        </Box>
    );
};
