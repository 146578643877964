import { observer } from 'mobx-react';
import { useDependencies } from 'shared/src/utils';
import { TeamsStore } from '@/modules/sports/stores/teams-store';
import { CommonTablePagination } from '@/modules/common/components/common-table-pagination/common-table-pagination';

export const TeamsPagination = observer(() => {
    const [{ tableState }] = useDependencies(TeamsStore);

    return <CommonTablePagination tableState={tableState} />;
});
