import { useContext } from 'react';

import { ContainerContext, Entries } from './common';

function useDependenciesInternal<T extends any[], P extends boolean>(
    entries: Entries<T>,
    optional: P
) {
    const container = useContext(ContainerContext);

    const instances = entries.map((entry) => {
        if (optional) {
            if (container.isBound(entry)) {
                return container.get(entry);
            }

            return undefined;
        }

        return container.get(entry);
    });

    return instances as P extends true ? Partial<T> : T;
}

export function useDependencies<T extends any[]>(...tokens: Entries<T>) {
    return useDependenciesInternal(tokens, false);
}

export function useOptionalDependencies<T extends any[]>(
    ...tokens: Entries<T>
) {
    return useDependenciesInternal(tokens, true);
}
