import { FC, Fragment } from 'react';
import { observer } from 'mobx-react';
import { IconButtonProps } from '@mui/material';
import { BetOddModel } from '@/modules/common/api/api';
import { Info } from '@phosphor-icons/react';
import IconButton from '@mui/material/IconButton';
import { usePopover } from '@/hooks/use-popover';
import { BetInfoPopover } from '@/modules/bet-grader/components/bet-info-button/bet-info-popover';

interface BetInfoButtonProps extends IconButtonProps {
    betId: BetOddModel['id'];
}

export const BetInfoButton: FC<BetInfoButtonProps> = observer(
    ({ betId, ...props }) => {
        const popover = usePopover<HTMLButtonElement>();

        return (
            <Fragment>
                <IconButton
                    {...props}
                    onClick={popover.handleOpen}
                    ref={popover.anchorRef}
                >
                    <Info />
                </IconButton>
                {popover.open && (
                    <BetInfoPopover
                        betId={betId}
                        anchorEl={popover.anchorRef.current}
                        onClose={popover.handleClose}
                    />
                )}
            </Fragment>
        );
    }
);
