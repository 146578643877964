import { injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';
import { FieldState, FormState } from 'formstate';
import {
    ApplicationFileModel,
    FileParameter,
    GameTemplatePublicModel,
    GameTemplateUpsertModel,
    IdNameModel,
    ImageUrlUploadModel,
    LeagueTitleModel,
    MatchModel,
} from '@/modules/common/api/api';
import { formStateValidators, noop } from 'shared/src/utils';
import { apiClient } from '@/modules/common/api/api-client';
import {
    ALL_OPTIONS_VALUE,
    INVALID_DATE_RANGE_ERROR,
} from 'shared/src/utils/constants';

@injectable()
export class GameTemplateEditStore {
    formState = new FormState({
        logo: new FieldState<Pick<ApplicationFileModel, 'id' | 'url'>>({
            id: '',
            url: '',
        }).validators((value) => formStateValidators.required(value.id)),
        name: new FieldState('').validators(formStateValidators.required),
        leagueIds: new FieldState<LeagueTitleModel['id'][]>([]),
        matchId: new FieldState<MatchModel['id']>(ALL_OPTIONS_VALUE),
        startDate: new FieldState<Date | null>(null).validators(
            formStateValidators.dateValidator
        ),
        endDate: new FieldState<Date | null>(null).validators(
            formStateValidators.dateValidator
        ),
    })
        .compose()
        .validators(($) => {
            if ($.startDate.$!.getTime() >= $.endDate.$!.getTime()) {
                return INVALID_DATE_RANGE_ERROR;
            }

            return false;
        });

    availableLeagues: IdNameModel[] = [];
    availableMatches: MatchModel[] = [];
    isUploadingLogo = false;
    private lastUploadedLogoId?: ApplicationFileModel['id'];

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    init = async (gameTemplate?: GameTemplatePublicModel) => {
        try {
            this.fetchLeagues().catch(noop);

            if (gameTemplate) {
                if (gameTemplate.leagues.length === 1) {
                    this.fetchMatches(gameTemplate.leagues[0].id).catch(noop);
                }

                const { logo, name, leagues, match, startDate, endDate } =
                    gameTemplate;

                this.formState.$.logo.onChange(logo ?? { id: '', url: '' });
                this.formState.$.name.onChange(name);
                this.formState.$.leagueIds.onChange(
                    leagues.map((league) => league.id)
                );
                this.formState.$.matchId.onChange(
                    match?.id ?? ALL_OPTIONS_VALUE
                );
                this.formState.$.startDate.onChange(startDate);
                this.formState.$.endDate.onChange(endDate);
            }
        } catch {
            //
        }
    };

    startLogoUploading = () => {
        this.isUploadingLogo = true;
    };

    endLogoUploading = () => {
        this.isUploadingLogo = false;
    };

    removeLastUploadedLogo = () => {
        if (this.lastUploadedLogoId) {
            apiClient.deleteFile(this.lastUploadedLogoId).catch(noop);
        }
    };

    uploadLogoDirectly = async (file: FileParameter) => {
        try {
            const newFile = await apiClient.uploadFile(undefined, file);

            this.formState.$.logo.onChange(newFile);
            this.removeLastUploadedLogo();
            this.lastUploadedLogoId = newFile.id;

            this.endLogoUploading();
        } catch {
            // skip
        }
    };

    uploadLogoByURL = async (url: string) => {
        try {
            const newFile = await apiClient.uploadFileByUrl(
                undefined,
                new ImageUrlUploadModel({ url })
            );

            this.formState.$.logo.onChange(newFile);
            this.removeLastUploadedLogo();
            this.lastUploadedLogoId = newFile.id;

            this.endLogoUploading();
        } catch {
            // skip
        }
    };

    selectLeagues = (
        leagueIds: (typeof this.availableLeagues)[number]['id'][]
    ) => {
        this.availableMatches = [];
        this.selectMatch(ALL_OPTIONS_VALUE);
        this.formState.$.leagueIds.onChange(leagueIds);

        if (leagueIds.length === 1) {
            this.fetchMatches(leagueIds[0]).catch(noop);
        }
    };

    getValidatedData = async () => {
        await this.formState.enableAutoValidationAndValidate();

        if (this.formState.hasError) {
            throw new Error('Validation error');
        }

        const formStateValues = this.formState.$;
        const isMatchSelected = formStateValues.matchId.$ !== ALL_OPTIONS_VALUE;

        return new GameTemplateUpsertModel({
            matchId: isMatchSelected ? formStateValues.matchId.$ : undefined,
            leagueIds: formStateValues.leagueIds.$,
            startDate: formStateValues.startDate.$!,
            endDate: formStateValues.endDate.$!,
            name: formStateValues.name.$,
            logoId: formStateValues.logo.$.id,
        });
    };

    selectMatch = (matchId: (typeof this.availableMatches)[number]['id']) => {
        if (matchId === ALL_OPTIONS_VALUE) {
            if (this.formState.$.matchId.value === ALL_OPTIONS_VALUE) {
                return;
            }

            this.formState.$.matchId.onChange(ALL_OPTIONS_VALUE);
            this.formState.$.startDate.reset();
            this.formState.$.endDate.reset();
        }

        const match = this.availableMatches.find(({ id }) => id === matchId);

        if (!match) return;

        this.formState.$.matchId.onChange(matchId);
        this.formState.$.startDate.onChange(match.startDate);
        this.formState.$.endDate.onChange(match.endDate);
    };

    fetchLeagues = async () => {
        try {
            const { items: availableLeagues } = await apiClient.getSportLeagues(
                undefined,
                undefined,
                undefined
            );

            runInAction(() => {
                this.availableLeagues = availableLeagues;
            });
        } catch {
            //
        }
    };

    fetchMatches = async (leagueId: number) => {
        try {
            const { items: availableMatches } = await apiClient.getMatchesAdmin(
                leagueId,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
            );

            runInAction(() => {
                this.availableMatches = availableMatches ?? [];
            });
        } catch {
            //
        }
    };
}
