import { injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus, TableState, TableStateDataGetter } from 'shared/src/utils';
import {
    BetOddModel,
    IdNameModel,
    MatchOddStatus,
    OddGradingSystem,
} from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';

@injectable()
export class BetGraderStore {
    tableState: TableState<BetOddModel>;
    availableLeagues: IdNameModel[] = [];
    selectedLeague: (typeof this.availableLeagues)[number]['id'] | null = null;

    availableMatches: IdNameModel[] = [];
    selectedMatch?: (typeof this.availableMatches)[number];
    matchesLoadStatus = LoadStatus.None;

    availableMarkets: IdNameModel[] = [];
    selectedMarket?: (typeof this.availableMarkets)[number];
    marketsLoadStatus = LoadStatus.None;

    graderFilter?: OddGradingSystem;
    resultFilter?: MatchOddStatus;

    betIdToGrade?: BetOddModel['id'];
    isPending = false;

    constructor() {
        this.tableState = new TableState(this.getData);
        makeAutoObservable(this);
    }

    init = async (isPending: boolean) => {
        runInAction(() => {
            this.isPending = isPending;
        });

        try {
            const { items: availableLeagues } = await apiClient.getSportLeagues(
                undefined,
                undefined,
                undefined
            );

            runInAction(() => {
                this.availableLeagues = availableLeagues;
            });

            await this.tableState.fetchData();
        } catch {
            //
        }
    };

    changeLeague = async (league: typeof this.selectedLeague) => {
        runInAction(() => {
            this.selectedLeague = league;
            this.selectedMatch = undefined;
            this.selectedMarket = undefined;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    applyMatchFilter = async (match: typeof this.selectedMatch) => {
        runInAction(() => {
            this.selectedMatch = match;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    applyMarketFilter = async (market: typeof this.selectedMarket) => {
        runInAction(() => {
            this.selectedMarket = market;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    applyGraderFilter = async (gradingSystem: typeof this.graderFilter) => {
        runInAction(() => {
            this.graderFilter = gradingSystem;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    applyResultFilter = async (result: typeof this.resultFilter) => {
        runInAction(() => {
            this.resultFilter = result;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    startGradeBet = (id: BetOddModel['id']) => {
        this.betIdToGrade = id;
    };

    endGradeBet = () => {
        this.betIdToGrade = undefined;
    };

    gradeBet = async (id: BetOddModel['id'], newStatus: MatchOddStatus) => {
        try {
            await apiClient.setOddStatus(id, newStatus);

            const editedDataItem = this.tableState.data.items.find(
                (item) => item.id === id
            );
            if (editedDataItem) {
                runInAction(() => {
                    editedDataItem.status = newStatus;
                });
            }

            this.endGradeBet();
        } catch {
            //
        }
    };

    fetchMatches = async (searchTerm?: string) => {
        runInAction(() => {
            this.matchesLoadStatus = LoadStatus.Loading;
        });

        try {
            const matches = await apiClient.getMatchesFromBets(
                this.selectedLeague ?? undefined,
                this.isPending,
                searchTerm,
                0,
                10
            );

            runInAction(() => {
                this.setMatches(matches.items ?? []);
                this.matchesLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.matchesLoadStatus = LoadStatus.Error;
            });
        }
    };

    fetchMarkets = async (searchTerm?: string) => {
        runInAction(() => {
            this.marketsLoadStatus = LoadStatus.Loading;
        });

        try {
            const matches = await apiClient.getMarketsFromBets(
                this.selectedLeague ?? undefined,
                this.isPending,
                searchTerm,
                0,
                10
            );

            runInAction(() => {
                this.setMarkets(matches.items ?? []);
                this.marketsLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.marketsLoadStatus = LoadStatus.Error;
            });
        }
    };

    setMatches = (matches: typeof this.availableMatches) => {
        this.availableMatches = matches;
    };

    setMarkets = (markets: typeof this.availableMarkets) => {
        this.availableMarkets = markets;
    };

    clearFilters = async () => {
        runInAction(() => {
            this.selectedMatch = undefined;
            this.selectedMarket = undefined;
            this.selectedLeague = null;
            this.graderFilter = undefined;
            this.resultFilter = undefined;
        });

        await this.tableState.fetchData({ page: 0, searchTerm: '' });
    };

    getData: TableStateDataGetter<BetOddModel> = async (
        filters,
        abortSignal
    ) => {
        const data = await apiClient.getOdds(
            this.selectedLeague ?? undefined,
            this.selectedMatch?.id,
            filters.searchTerm,
            this.selectedMarket?.id,
            this.isPending,
            this.resultFilter,
            this.graderFilter,
            Math.round(filters.page * filters.rowsPerPage),
            filters.rowsPerPage,
            abortSignal
        );

        return {
            items: data.items ?? [],
            total: data.totalAmount,
        };
    };

    get hasFilters() {
        return (
            this.selectedLeague !== null ||
            this.selectedMatch !== undefined ||
            this.selectedMarket !== undefined ||
            this.graderFilter !== undefined ||
            this.resultFilter !== undefined ||
            this.tableState.filters.searchTerm !== ''
        );
    }
}
