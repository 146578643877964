import { FC, Fragment } from 'react';
import { observer } from 'mobx-react';
import { IconButtonProps } from '@mui/material';
import { GameLeagueAdminModel } from '@/modules/common/api/api';
import { Info } from '@phosphor-icons/react';
import IconButton from '@mui/material/IconButton';
import { usePopover } from '@/hooks/use-popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { PopoverInfoItem } from '@/modules/common/components/popover-info-item/popover-info-item';
import Popover from '@mui/material/Popover';
import { Balance } from 'shared/src/components/balance/balance';

interface GameLeagueInfoButtonProps extends IconButtonProps {
    gameLeague: GameLeagueAdminModel;
}

export const GameLeagueInfoButton: FC<GameLeagueInfoButtonProps> = observer(
    ({ gameLeague, ...props }) => {
        const popover = usePopover<HTMLButtonElement>();

        const {
            createdByPlayer,
            initialBalance,
            maxPlayerCount,
            currentPlayerCount,
            playoffPlayerCount,
        } = gameLeague;

        return (
            <Fragment>
                <IconButton
                    {...props}
                    onClick={popover.handleOpen}
                    ref={popover.anchorRef}
                >
                    <Info />
                </IconButton>
                <Popover
                    anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom',
                    }}
                    anchorEl={popover.anchorRef.current}
                    onClose={popover.handleClose}
                    open={popover.open}
                    slotProps={{ paper: { sx: { width: '300px' } } }}
                    transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                    }}
                >
                    <Box sx={{ p: 2 }}>
                        <Box mb={2}>
                            <Typography variant="h6">Info</Typography>
                        </Box>
                        <Stack spacing={2}>
                            <PopoverInfoItem
                                field={'Created by:'}
                                value={`@${createdByPlayer.name}`}
                            />
                            <PopoverInfoItem
                                field={'Initial Balance:'}
                                value={
                                    <Balance
                                        typographyProps={{ variant: 'body2' }}
                                        amount={initialBalance}
                                    />
                                }
                            />
                            <PopoverInfoItem
                                field={'Max Players:'}
                                value={maxPlayerCount}
                            />
                            <PopoverInfoItem
                                field={'Participants:'}
                                value={currentPlayerCount}
                            />
                            <PopoverInfoItem
                                field={'PLayoff Players:'}
                                value={playoffPlayerCount}
                            />
                        </Stack>
                    </Box>
                </Popover>
            </Fragment>
        );
    }
);
