import { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { dayjs } from '@/lib/dayjs';
import { ColumnDef, DataTable } from '@/components/core/data-table';
import { SportTeamModel } from '@/modules/common/api/api';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { LoadStatus, useDependencies } from 'shared/src/utils';
import { TeamsStore } from '@/modules/sports/stores/teams-store';
import { ImageUploader } from '@/modules/common/components/image-uploader/image-uploader';
import { Image as ImageIcon } from '@phosphor-icons/react/dist/ssr/Image';
import Avatar from '@mui/material/Avatar';
import { TableLoadingWrapper } from '@/modules/common/components/table-loading-wrapper/table-loading-wrapper';

export const TeamsTable = observer(() => {
    const [
        {
            tableState,
            teamIdToUploadLogo,
            uploadLogoDirectly,
            startLogoUploading,
            endLogoUploading,
            updateLogoURL,
        },
    ] = useDependencies(TeamsStore);

    const columns = useMemo(
        () =>
            [
                {
                    formatter: (row) => (
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ alignItems: 'center' }}
                        >
                            {row.logo?.url ? (
                                <Avatar
                                    variant={'rounded'}
                                    src={row.logo.url}
                                    sx={{
                                        '& img': {
                                            objectFit: 'unset',
                                        },
                                    }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        bgcolor:
                                            'var(--mui-palette-background-level2)',
                                        borderRadius: 1,
                                        display: 'flex',
                                        height: '40px',
                                        justifyContent: 'center',
                                        width: '40px',
                                    }}
                                >
                                    <ImageIcon fontSize="var(--icon-fontSize-lg)" />
                                </Box>
                            )}
                            <Typography variant={'inherit'}>
                                {row.name}
                            </Typography>
                        </Stack>
                    ),
                    name: 'Team',
                    width: '250px',
                },
                {
                    formatter: (row) => row.league?.name,
                    name: 'League',
                    width: '250px',
                },
                {
                    formatter: (row) =>
                        row.logo?.uploadDate
                            ? dayjs(row.logo?.uploadDate).format(
                                  'M/D/YY h:mm A'
                              )
                            : '-',
                    name: 'Modified at',
                    width: '200px',
                },
                {
                    formatter: (row) => {
                        return (
                            <Button
                                variant={'outlined'}
                                onClick={() => startLogoUploading(row.id)}
                            >
                                Upload Logo
                            </Button>
                        );
                    },
                    name: 'Actions',
                    width: '100px',
                    align: 'right',
                },
            ] satisfies ColumnDef<SportTeamModel>[],
        [startLogoUploading]
    );

    const data = tableState.data.items;

    return (
        <TableLoadingWrapper
            isLoading={tableState.loadStatus === LoadStatus.Loading}
        >
            <DataTable<SportTeamModel> columns={columns} rows={data} />
            {!data.length ? (
                <Box sx={{ p: 3 }}>
                    <Typography
                        color="text.secondary"
                        sx={{ textAlign: 'center' }}
                        variant="body2"
                    >
                        No teams found
                    </Typography>
                </Box>
            ) : null}
            {teamIdToUploadLogo !== undefined && (
                <ImageUploader
                    onClose={endLogoUploading}
                    onDirectUpload={(file) => {
                        uploadLogoDirectly(teamIdToUploadLogo, {
                            fileName: file.name,
                            data: file,
                        });
                    }}
                    onURLUpload={(url) => {
                        updateLogoURL(teamIdToUploadLogo, url);
                    }}
                />
            )}
        </TableLoadingWrapper>
    );
});
