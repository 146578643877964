import moment from 'moment/moment';

const phoneNumberRegex = /^\d{10,20}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /^[a-zA-Z0-9!@#$%^&*_+=-]{6,50}$/;
const usernameRegex = /^[A-Za-z0-9_]{1,20}$/;

const required = (value: string) => !value.trim() && 'Required';
const phoneNumber = (value: string) =>
    !phoneNumberRegex.test(value) && 'Invalid Phone Number';
const email = (value: string) =>
    !emailRegex.test(value) && 'Invalid Email Address';
const password = (value: string) =>
    !passwordRegex.test(value) && 'Invalid Password';
const username = (value: string) =>
    !usernameRegex.test(value) && 'Invalid Username';

const dateValidator = (value: Date | null) =>
    (!value || !moment(value).isValid()) && 'Invalid Date';

const optionalValidDate = (value: Date | null | undefined) =>
    value && !moment(value).isValid() && 'Invalid Date';

const requiredLength = (value: unknown[]) => !value.length && 'Required';

export const formStateValidators = {
    required,
    phoneNumber,
    email,
    username,
    password,
    dateValidator,
    optionalValidDate,
    requiredLength,
};
