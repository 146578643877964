import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { FieldState, FormState } from 'formstate';
import {
    GameLeagueAdminModel,
    GameLeagueUpdateAdminModel,
} from '@/modules/common/api/api';
import { formStateValidators } from 'shared/src/utils';

@injectable()
export class GameLeagueEditStore {
    formState = new FormState({
        name: new FieldState('').validators(formStateValidators.required),
    });

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    init = (gameLeague: GameLeagueAdminModel) => {
        this.formState.$.name.onChange(gameLeague.name);
    };

    getValidatedData = async () => {
        await this.formState.enableAutoValidationAndValidate();

        if (this.formState.hasError) {
            throw new Error('Validation error');
        }

        const formStateValues = this.formState.$;

        const validatedData = new GameLeagueUpdateAdminModel({
            name: formStateValues.name.$,
        });

        return validatedData;
    };
}
