import {
    FilterPopover,
    useFilterContext,
} from '@/components/core/filter-button';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { GameLeagueStateEnum } from '@/modules/common/api/api';
import Select from '@mui/material/Select';
import { Option } from '@/components/core/option';

export const StateFilterPopover = () => {
    const {
        anchorEl,
        onApply,
        onClose,
        open,
        value: initialValue,
    } = useFilterContext();

    const [value, setValue] = useState(
        (initialValue as GameLeagueStateEnum | undefined) ?? ''
    );

    return (
        <FilterPopover
            anchorEl={anchorEl}
            onClose={onClose}
            open={open}
            title="Filter by State"
        >
            <FormControl>
                <Select
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    value={value}
                >
                    <Option value={''}>Select State</Option>
                    {Object.values(GameLeagueStateEnum).map((option) => (
                        <Option value={option} key={option}>
                            {option}
                        </Option>
                    ))}
                </Select>
            </FormControl>
            <Button
                onClick={() => {
                    onApply(value || undefined);
                }}
                variant="contained"
            >
                Apply
            </Button>
        </FilterPopover>
    );
};
