import { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { dayjs } from '@/lib/dayjs';
import { ColumnDef, DataTable } from '@/components/core/data-table';
import { SportLeagueModel } from '@/modules/common/api/api';
import Box from '@mui/material/Box';
import { LoadStatus, useDependencies } from 'shared/src/utils';
import Avatar from '@mui/material/Avatar';
import { SportLeaguesStore } from '@/modules/sports/stores/sport-leagues-store';
import { PencilSimple } from '@phosphor-icons/react';
import IconButton from '@mui/material/IconButton';
import { SportLeagueEditDialog } from '@/modules/sports/components/leagues/sport-league-edit-dialog/sport-league-edit-dialog';
import { TableLoadingWrapper } from '@/modules/common/components/table-loading-wrapper/table-loading-wrapper';

export const SportLeaguesTable = observer(() => {
    const [
        {
            tableState,
            sportLeagueToEdit,
            updateLeague,
            startLeagueEditing,
            endLeagueEditing,
        },
    ] = useDependencies(SportLeaguesStore);

    const columns = useMemo(
        () =>
            [
                {
                    name: 'League',
                    width: '250px',
                    formatter: (row) => (
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ alignItems: 'center' }}
                        >
                            {!!row.logo?.url && (
                                <Avatar
                                    variant={'rounded'}
                                    src={row.logo.url}
                                    sx={{
                                        '& img': {
                                            objectFit: 'unset',
                                        },
                                    }}
                                />
                            )}
                            <Typography variant={'inherit'}>
                                {row.name}
                            </Typography>
                        </Stack>
                    ),
                },
                {
                    formatter: (row) => row.sport,
                    name: 'Sport',
                    width: '250px',
                },
                {
                    formatter: (row) =>
                        row.seasonStartDate
                            ? dayjs(row.seasonStartDate).format('M/D/YY')
                            : '-',
                    name: 'Season Start Date',
                    width: '200px',
                },
                {
                    formatter: (row) =>
                        row.seasonEndDate
                            ? dayjs(row.seasonEndDate).format('M/D/YY')
                            : '-',
                    name: 'Season End Date',
                    width: '200px',
                },
                {
                    formatter: (row) => {
                        return (
                            <IconButton
                                color={'primary'}
                                onClick={() => startLeagueEditing(row)}
                            >
                                <PencilSimple weight={'bold'} />
                            </IconButton>
                        );
                    },
                    name: 'Actions',
                    width: '100px',
                    align: 'right',
                },
            ] satisfies ColumnDef<SportLeagueModel>[],
        [startLeagueEditing]
    );

    const data = tableState.data.items;

    return (
        <TableLoadingWrapper
            isLoading={tableState.loadStatus === LoadStatus.Loading}
        >
            <DataTable<SportLeagueModel> columns={columns} rows={data} />
            {!data.length ? (
                <Box sx={{ p: 3 }}>
                    <Typography
                        color="text.secondary"
                        sx={{ textAlign: 'center' }}
                        variant="body2"
                    >
                        No leagues found
                    </Typography>
                </Box>
            ) : null}
            {!!sportLeagueToEdit && (
                <SportLeagueEditDialog
                    onClose={endLeagueEditing}
                    onApply={updateLeague}
                    initialValue={sportLeagueToEdit}
                />
            )}
        </TableLoadingWrapper>
    );
});
