export const isBigNumber = (value: number) => {
    return value >= 1_000_000;
};

export const formatBigNumber = (value: number) => {
    if (value >= 1_000_000_000) {
        return Math.floor(value / 1_000_000).toLocaleString() + 'M';
    } else if (value >= 1_000_000) {
        return Math.floor(value / 1_000).toLocaleString() + 'K';
    } else {
        return value.toLocaleString();
    }
};
