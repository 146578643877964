import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
    FilterPopover,
    useFilterContext,
} from '@/components/core/filter-button';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';

export const BetNameFilterPopover = observer(() => {
    const {
        anchorEl,
        onApply,
        onClose,
        open,
        value: initialValue,
    } = useFilterContext();
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue((initialValue as string | undefined) ?? '');
    }, [initialValue]);

    return (
        <FilterPopover
            anchorEl={anchorEl}
            onClose={onClose}
            open={open}
            title="Filter by Bet Name"
        >
            <FormControl>
                <OutlinedInput
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            onApply(value);
                        }
                    }}
                    value={value}
                />
            </FormControl>
            <Button
                onClick={() => {
                    onApply(value.trim());
                }}
                variant="contained"
            >
                Apply
            </Button>
        </FilterPopover>
    );
});
