import { observer } from 'mobx-react';
import { useDependencies } from 'shared/src/utils';
import { GameLeaguesStore } from '@/modules/game-leagues/stores/game-leagues-store';
import { DebouncedSearch } from 'shared/src/components/debounced-search/debounced-search';
import { FilterButton } from '@/components/core/filter-button';
import Stack from '@mui/material/Stack';
import { StatusFilterPopover } from '@/modules/game-leagues/components/game-leagues-content/filter-popovers/status-filter-popover';
import { StateFilterPopover } from '@/modules/game-leagues/components/game-leagues-content/filter-popovers/state-filter-popover';
import {
    GameLeagueStateEnum,
    GameLeagueStatusEnum,
} from '@/modules/common/api/api';

export const GameLeaguesFilters = observer(() => {
    const [
        {
            tableState,
            applyStateFilter,
            stateFilter,
            applyStatusFilter,
            statusFilter,
        },
    ] = useDependencies(GameLeaguesStore);

    return (
        <div>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 2,
                }}
            >
                <DebouncedSearch
                    placeholder={'Search by name'}
                    onSearch={tableState.search}
                />
                <Stack direction={'row'} spacing={2}>
                    <FilterButton
                        displayValue={stateFilter}
                        onFilterApply={(value) => {
                            applyStateFilter(
                                value as GameLeagueStateEnum | undefined
                            );
                        }}
                        onFilterDelete={() => applyStateFilter(undefined)}
                        label={'State'}
                        popover={<StateFilterPopover />}
                        value={stateFilter}
                    />
                    <FilterButton
                        displayValue={statusFilter}
                        onFilterApply={(value) => {
                            applyStatusFilter(
                                value as GameLeagueStatusEnum | undefined
                            );
                        }}
                        onFilterDelete={() => applyStatusFilter(undefined)}
                        label={'Status'}
                        popover={<StatusFilterPopover />}
                        value={statusFilter}
                    />
                </Stack>
            </Stack>
        </div>
    );
});
