import { injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';
import { apiClient } from '@/modules/common/api/api-client';
import {
    FileParameter,
    IdNameModel,
    ImageUrlUploadModel,
    SportPlayerModel,
} from '@/modules/common/api/api';
import { LoadStatus, TableState, TableStateDataGetter } from 'shared/src/utils';

export enum PhotoFilterEnum {
    NoPhoto = 'No Photo',
    Uploaded = 'Uploaded',
}

@injectable()
export class PlayersStore {
    tableState: TableState<SportPlayerModel>;
    availableLeagues: IdNameModel[] = [];
    photoFilter?: PhotoFilterEnum;
    selectedLeague: (typeof this.availableLeagues)[number]['id'] | null = null;

    availableTeams: IdNameModel[] = [];
    selectedTeam?: (typeof this.availableTeams)[number];
    teamsLoadStatus = LoadStatus.None;

    playerIdToUploadLogo?: SportPlayerModel['id'];

    constructor() {
        this.tableState = new TableState(this.getData);
        makeAutoObservable(this);
    }

    applyPhotoFilter = async (photoFilter: typeof this.photoFilter) => {
        runInAction(() => {
            this.photoFilter = photoFilter;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    applyTeamFilter = async (team: typeof this.selectedTeam) => {
        runInAction(() => {
            this.selectedTeam = team;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    fetchTeams = async (searchTerm?: string) => {
        runInAction(() => {
            this.teamsLoadStatus = LoadStatus.Loading;
        });

        try {
            const teams = await apiClient.sportTeams(
                this.selectedLeague ?? undefined,
                searchTerm,
                undefined,
                undefined,
                0,
                10
            );

            runInAction(() => {
                this.setTeams(teams.items ?? []);
                this.teamsLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.teamsLoadStatus = LoadStatus.Error;
            });
        }
    };

    setTeams = (teams: typeof this.availableTeams) => {
        this.availableTeams = teams;
    };

    startPhotoUploading = (id: typeof this.playerIdToUploadLogo) => {
        this.playerIdToUploadLogo = id;
    };

    endPhotoUploading = () => {
        this.playerIdToUploadLogo = undefined;
    };

    uploadPhotoDirectly = async (
        id: SportPlayerModel['id'],
        file: FileParameter
    ) => {
        try {
            await apiClient.updateSportPlayerLogoByFile(id, file);
            this.endPhotoUploading();
            await this.tableState.fetchData();
        } catch (err) {
            console.error(err);
        }
    };

    updatePhotoURL = async (id: SportPlayerModel['id'], url: string) => {
        try {
            await apiClient.updateSportPlayerLogoByUrl(
                id,
                new ImageUrlUploadModel({ url })
            );
            this.endPhotoUploading();
            await this.tableState.fetchData();
        } catch (err) {
            console.error(err);
        }
    };

    init = async () => {
        try {
            const { items: availableLeagues } = await apiClient.getSportLeagues(
                undefined,
                undefined,
                undefined
            );

            runInAction(() => {
                this.availableLeagues = availableLeagues;
            });

            await this.tableState.fetchData();
        } catch {
            //
        }
    };

    changeLeague = async (league: typeof this.selectedLeague) => {
        runInAction(() => {
            this.selectedLeague = league;
            this.selectedTeam = undefined;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    getData: TableStateDataGetter<SportPlayerModel> = async (
        filters,
        abortSignal
    ) => {
        const data = await apiClient.getSportPlayers(
            this.selectedLeague ?? undefined,
            filters.searchTerm,
            this.photoFilter !== undefined
                ? this.photoFilter === PhotoFilterEnum.Uploaded
                : undefined,
            undefined,
            this.selectedTeam?.id,
            Math.round(filters.page * filters.rowsPerPage),
            filters.rowsPerPage,
            abortSignal
        );

        return {
            items: data.items ?? [],
            total: data.totalAmount,
        };
    };
}
