import { useEffect, useState } from 'react';
import {
    FilterPopover,
    useFilterContext,
} from '@/components/core/filter-button';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import { OddGradingSystem } from '@/modules/common/api/api';

export const GraderFilterPopover = () => {
    const {
        anchorEl,
        onApply,
        onClose,
        open,
        value: initialValue,
    } = useFilterContext();

    const [value, setValue] = useState(
        (initialValue as OddGradingSystem | undefined) ?? null
    );

    useEffect(() => {
        setValue((initialValue as OddGradingSystem | undefined) ?? null);
    }, [initialValue]);

    return (
        <FilterPopover
            anchorEl={anchorEl}
            onClose={onClose}
            open={open}
            title="Filter by Grader"
        >
            <Stack direction={'row'} justifyContent={'center'}>
                <FormControl>
                    <RadioGroup
                        value={value}
                        name="graderFilter"
                        row
                        onChange={(_, newValue) => {
                            setValue(newValue as OddGradingSystem);
                        }}
                    >
                        {Object.values(OddGradingSystem).map((option) => (
                            <FormControlLabel
                                control={<Radio />}
                                key={option}
                                label={option}
                                value={option}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Stack>
            <Button
                onClick={() => {
                    onApply(value ?? undefined);
                }}
                variant="contained"
            >
                Apply
            </Button>
        </FilterPopover>
    );
};
