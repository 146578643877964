import { FC, Fragment, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { File, FileDropzone } from '@/components/core/file-dropzone';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { X as XIcon } from '@phosphor-icons/react/dist/ssr/X';
import DialogContent from '@mui/material/DialogContent';
import { FileIcon } from '@/components/core/file-icon';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { toast } from '@/components/core/toaster';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';

interface ImageUploaderProps {
    onClose: () => void;
    onDirectUpload: (file: File) => void;
    onURLUpload: (url: string) => void;
}

interface FileInfo {
    size: number | 'Unknown';
    extension: string | 'Unknown';
    name: string | 'Unknown';
}

const acceptedFileTypes = {
    'image/png': ['.png'],
    'image/svg+xml': ['.svg'],
    'image/webp': ['.webp'],
};

const acceptedExtensionNames = Object.values(acceptedFileTypes)
    .flat()
    .join(', ');

// const maxSize = 512 * 1024;

function bytesToSize(bytes: number, decimals = 2): string {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const ImageUploader: FC<ImageUploaderProps> = observer(
    ({ onClose, onDirectUpload, onURLUpload }) => {
        const [imagePreviewSource, setImagePreviewSource] = useState<
            string | undefined
        >();
        const [fileInfo, setFileInfo] = useState<FileInfo | undefined>();
        const [selectedFile, setSelectedFile] = useState<File | undefined>();
        const [selectedURL, setSelectedURL] = useState('');

        const handleSelectFile = useCallback((newFiles: File[]) => {
            if (!newFiles.length) return;

            const newFile = newFiles[0];

            const reader = new FileReader();
            reader.onload = () => {
                setSelectedFile(newFile);
                setFileInfo({
                    extension: newFile.name.split('.').at(-1) ?? 'Unknown',
                    size: newFile.size,
                    name: newFile.name,
                });
                setImagePreviewSource(
                    typeof reader.result === 'string'
                        ? reader.result
                        : undefined
                );
                setSelectedURL('');
            };
            reader.onerror = () => {
                toast.error('Something went wrong!');
            };
            reader.readAsDataURL(newFile);
        }, []);

        const handleRemove = useCallback(() => {
            setSelectedFile(undefined);
            setImagePreviewSource(undefined);
        }, []);

        const handleUpload = () => {
            if (selectedFile) {
                onDirectUpload(selectedFile);
            } else if (selectedURL) {
                onURLUpload(selectedURL);
            } else {
                toast.error('Please upload a file or provide an image URL.');
            }
        };

        const handleSelectURL = async () => {
            try {
                const response = await fetch(selectedURL, { method: 'HEAD' });
                const contentType = response.headers.get('Content-Type');
                const contentLength = response.headers.get('Content-Length');

                if (
                    !Object.keys(acceptedFileTypes).includes(contentType || '')
                ) {
                    toast.error(
                        `Only the following types are allowed: ${acceptedExtensionNames}!`
                    );
                    return;
                }

                const size = contentLength
                    ? parseInt(contentLength)
                    : 'Unknown';

                setSelectedURL(selectedURL);
                setImagePreviewSource(selectedURL);
                setFileInfo({
                    size,
                    extension: contentType?.split('/')[1] || 'Unknown',
                    name: selectedURL.split('/').at(-1) || 'Unknown',
                });
                setSelectedFile(undefined);
            } catch (error) {
                toast.error(
                    'Invalid image URL or unable to retrieve file info.'
                );
            }
        };

        return (
            <Dialog fullWidth maxWidth="sm" onClose={onClose} open>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: 3,
                        py: 2,
                    }}
                >
                    <Typography variant="h6">Upload files</Typography>
                    <IconButton onClick={onClose}>
                        <XIcon />
                    </IconButton>
                </Stack>
                <DialogContent>
                    <Stack spacing={3}>
                        {imagePreviewSource ? (
                            <Fragment>
                                <Stack
                                    justifyContent={'center'}
                                    direction={'row'}
                                >
                                    <img
                                        src={imagePreviewSource}
                                        alt="Uploaded"
                                        style={{
                                            height: '400px',
                                            width: 'auto',
                                        }}
                                    />
                                </Stack>
                                {!!fileInfo && (
                                    <Stack spacing={2}>
                                        <Stack
                                            component="ul"
                                            spacing={1}
                                            sx={{
                                                listStyle: 'none',
                                                m: 0,
                                                p: 0,
                                            }}
                                        >
                                            <Stack
                                                component="li"
                                                direction="row"
                                                spacing={2}
                                                sx={{
                                                    alignItems: 'center',
                                                    border: '1px solid var(--mui-palette-divider)',
                                                    borderRadius: 1,
                                                    flex: '1 1 auto',
                                                    p: 1,
                                                }}
                                            >
                                                <FileIcon
                                                    extension={
                                                        fileInfo?.extension
                                                    }
                                                />
                                                <Box sx={{ flex: '1 1 auto' }}>
                                                    <Typography variant="subtitle2">
                                                        {fileInfo.name}
                                                    </Typography>
                                                    <Typography
                                                        color="text.secondary"
                                                        variant="body2"
                                                    >
                                                        {typeof fileInfo.size ===
                                                        'number'
                                                            ? bytesToSize(
                                                                  fileInfo.size
                                                              )
                                                            : 'Unknown size'}
                                                    </Typography>
                                                </Box>
                                                <Tooltip title="Remove">
                                                    <IconButton
                                                        onClick={handleRemove}
                                                    >
                                                        <XIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <FileDropzone
                                    // maxSize={maxSize}
                                    accept={acceptedFileTypes}
                                    caption={`Max file size is 512 KB, (${acceptedExtensionNames})`}
                                    onDrop={handleSelectFile}
                                />
                                <Typography variant={'body1'} align={'center'}>
                                    or
                                </Typography>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        Enter an image URL (
                                        {acceptedExtensionNames})
                                    </InputLabel>
                                    <OutlinedInput
                                        onChange={(e) => {
                                            setSelectedURL(e.target.value);
                                        }}
                                        value={selectedURL}
                                        endAdornment={
                                            <Button
                                                variant={'outlined'}
                                                size="small"
                                                disabled={!selectedURL}
                                                onClick={handleSelectURL}
                                            >
                                                Add
                                            </Button>
                                        }
                                        name="name"
                                    />
                                </FormControl>
                            </Fragment>
                        )}
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                onClick={handleUpload}
                                size="small"
                                type="button"
                                variant="contained"
                                disabled={!imagePreviewSource}
                            >
                                Upload
                            </Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        );
    }
);
