import { formStateValidators, injectable, noop } from 'shared/src/utils';
import { makeAutoObservable } from 'mobx';
import { FieldState, FormState } from 'formstate';
import {
    ApplicationFileModel,
    FileParameter,
    ImageUrlUploadModel,
    SportLeagueModel,
    SportLeagueUpdateModel,
} from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';

@injectable()
export class SportLeagueEditStore {
    formState = new FormState({
        logo: new FieldState<Pick<ApplicationFileModel, 'id' | 'url'>>({
            id: '',
            url: '',
        }).validators((value) => formStateValidators.required(value.id)),
        name: new FieldState('').validators(formStateValidators.required),
        seasonStartDate: new FieldState<Date | undefined>(undefined).validators(
            formStateValidators.optionalValidDate
        ),
        seasonEndDate: new FieldState<Date | undefined>(undefined).validators(
            formStateValidators.optionalValidDate
        ),
    });

    isUploadingLogo = false;
    private lastUploadedLogoId?: ApplicationFileModel['id'];

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    init = async (sportLeague: SportLeagueModel) => {
        const { logo, name, seasonStartDate, seasonEndDate } = sportLeague;

        this.formState.$.logo.onChange(logo);
        this.formState.$.name.onChange(name);
        this.formState.$.seasonStartDate.onChange(seasonStartDate);
        this.formState.$.seasonEndDate.onChange(seasonEndDate);
    };

    startLogoUploading = () => {
        this.isUploadingLogo = true;
    };

    endLogoUploading = () => {
        this.isUploadingLogo = false;
    };

    removeLastUploadedLogo = () => {
        if (this.lastUploadedLogoId) {
            apiClient.deleteFile(this.lastUploadedLogoId).catch(noop);
        }
    };

    uploadLogoDirectly = async (file: FileParameter) => {
        try {
            const newFile = await apiClient.uploadFile(undefined, file);

            this.formState.$.logo.onChange(newFile);
            this.removeLastUploadedLogo();
            this.lastUploadedLogoId = newFile.id;

            this.endLogoUploading();
        } catch {
            // skip
        }
    };

    uploadLogoByURL = async (url: string) => {
        try {
            const newFile = await apiClient.uploadFileByUrl(
                undefined,
                new ImageUrlUploadModel({ url })
            );

            this.formState.$.logo.onChange(newFile);
            this.removeLastUploadedLogo();
            this.lastUploadedLogoId = newFile.id;

            this.endLogoUploading();
        } catch {
            // skip
        }
    };

    getValidatedData = async () => {
        await this.formState.enableAutoValidationAndValidate();

        if (this.formState.hasError) {
            throw new Error('Validation error');
        }

        const { name, seasonStartDate, seasonEndDate, logo } = this.formState.$;

        return new SportLeagueUpdateModel({
            name: name.$,
            seasonStartDate: seasonStartDate.$,
            seasonEndDate: seasonEndDate.$,
            logoId: logo.$.id,
        });
    };
}
