import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import { X as XIcon } from '@phosphor-icons/react/dist/csr/X';
import IconButton from '@mui/material/IconButton';
import { PlayerAdminModel } from '@/modules/common/api/api';
import { useDependencies } from 'shared/src/utils';
import { UsersStore } from '@/modules/users/stores/users-store';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { dayjs } from '@/lib/dayjs';

interface UserNotesDialogProps {
    user: PlayerAdminModel;
}

export const UserNotesDialog: FC<UserNotesDialogProps> = observer(
    ({ user }) => {
        const [{ endViewingNotes, addNote }] = useDependencies(UsersStore);
        const [note, setNote] = useState('');

        const handleAddNode = () => {
            addNote(note);
            setNote('');
        };

        return (
            <Dialog open onClose={endViewingNotes} maxWidth={'sm'} fullWidth>
                <DialogTitle>Notes History</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={endViewingNotes}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <XIcon />
                </IconButton>
                <DialogContent>
                    <Stack px={2} spacing={2} mb={4}>
                        {user.notes.map(({ id, text, createDate }) => {
                            return (
                                <Stack key={id}>
                                    <Typography variant={'body2'}>
                                        {text}
                                    </Typography>
                                    <Typography variant={'caption'}>
                                        {dayjs(createDate).format(
                                            'DD MMM YYYY HH:MM a'
                                        )}
                                    </Typography>
                                </Stack>
                            );
                        })}
                    </Stack>
                    <OutlinedInput
                        fullWidth
                        placeholder={'Add a note'}
                        value={note}
                        onChange={(event) => {
                            setNote(event.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button
                        variant={'contained'}
                        onClick={handleAddNode}
                        disabled={!note.trim()}
                    >
                        Add Note
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);
