import { FunctionComponent } from 'react';

import { Provider } from './provider';
import { Entry } from './common';

export const provide =
    (entries: Entry<any>[]) =>
    <P extends {}>(Component: FunctionComponent<P>) =>
        ((props: any) => (
            <Provider entries={entries}>
                <Component {...props} />
            </Provider>
        )) as FunctionComponent<P>;
