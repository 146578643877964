import { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import { LoadStatus, useDependencies } from 'shared/src/utils';
import { GameLeaguesStore } from '@/modules/game-leagues/stores/game-leagues-store';
import { useConfirm } from 'shared/src/hooks';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { dayjs } from '@/lib/dayjs';
import {
    GameLeagueAdminModel,
    GameLeagueStatusEnum,
} from '@/modules/common/api/api';
import IconButton from '@mui/material/IconButton';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { ColumnDef, DataTable } from '@/components/core/data-table';
import Box from '@mui/material/Box';
import { GameLeagueEditDialog } from '@/modules/game-leagues/components/game-league-edit-dialog/game-league-edit-dialog';
import Chip from '@mui/material/Chip';
import { ChipProps } from '@mui/material';
import { GameLeagueInfoButton } from '@/modules/game-leagues/components/game-leagues-content/game-league-info-button/game-league-info-button';
import { TableLoadingWrapper } from '@/modules/common/components/table-loading-wrapper/table-loading-wrapper';

export const GameLeaguesTable = observer(() => {
    const [
        {
            tableState,
            gameLeagueToEdit,
            startEditing,
            endEditing,
            editLeague,
            deleteGameLeague,
        },
    ] = useDependencies(GameLeaguesStore);

    const [Confirmation, handleDeleteGameLeagueWithConfirmation] =
        useConfirm(deleteGameLeague);

    const columns = useMemo(() => {
        return [
            {
                name: 'Name',
                width: '150px',
                formatter: (row) => row.name,
            },
            {
                formatter: (row) =>
                    row.leagues.map(({ name }) => name).join(', '),
                name: 'Leagues',
                width: '250px',
            },
            {
                formatter: (row) => {
                    return row.startDate
                        ? dayjs(row.startDate).format('M/D/YY h:mm A')
                        : '-';
                },
                name: 'Start Date',
                width: '150px',
            },
            {
                formatter: (row) => {
                    return row.endDate
                        ? dayjs(row.endDate).format('M/D/YY h:mm A')
                        : '-';
                },
                name: 'End Date',
                width: '150px',
            },
            {
                formatter: (row) => {
                    const mapStatusToColor: Record<
                        GameLeagueStatusEnum,
                        ChipProps['color']
                    > = {
                        [GameLeagueStatusEnum.Active]: 'default',
                        [GameLeagueStatusEnum.Completed]: 'success',
                        [GameLeagueStatusEnum.Cancelled]: 'error',
                    };

                    return (
                        <Chip
                            variant={'outlined'}
                            color={mapStatusToColor[row.status]}
                            label={row.status}
                        />
                    );
                },
                name: 'Status',
                width: '150px',
            },
            {
                formatter: (row) => {
                    return <GameLeagueInfoButton gameLeague={row} />;
                },
                name: 'Info',
                width: '150px',
            },
            {
                formatter: (row) => {
                    return (
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                            justifyContent={'end'}
                        >
                            <IconButton
                                color={'error'}
                                onClick={() =>
                                    handleDeleteGameLeagueWithConfirmation(
                                        row.id
                                    )
                                }
                            >
                                <Trash weight={'bold'} />
                            </IconButton>
                            <IconButton
                                color={'primary'}
                                onClick={() => startEditing(row)}
                            >
                                <PencilSimple weight={'bold'} />
                            </IconButton>
                        </Stack>
                    );
                },
                name: 'Actions',
                width: '150px',
                align: 'right',
            },
        ] satisfies ColumnDef<GameLeagueAdminModel>[];
    }, [startEditing]);

    const data = tableState.data.items;

    return (
        <TableLoadingWrapper
            isLoading={tableState.loadStatus === LoadStatus.Loading}
        >
            <DataTable<GameLeagueAdminModel> columns={columns} rows={data} />
            {!data.length ? (
                <Box sx={{ p: 3 }}>
                    <Typography
                        color="text.secondary"
                        sx={{ textAlign: 'center' }}
                        variant="body2"
                    >
                        No leagues found
                    </Typography>
                </Box>
            ) : null}
            {!!gameLeagueToEdit && (
                <GameLeagueEditDialog
                    onClose={endEditing}
                    onApply={editLeague}
                    initialValue={gameLeagueToEdit}
                />
            )}
            <Confirmation
                title={''}
                primaryActionName={'Delete'}
                message={'Are you sure you want to delete this league?'}
            />
        </TableLoadingWrapper>
    );
});
