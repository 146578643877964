import { TableState } from 'shared/src/utils';
import { FC } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { observer } from 'mobx-react';

interface CommonTablePaginationProps {
    tableState: TableState<unknown>;
}

export const CommonTablePagination: FC<CommonTablePaginationProps> = observer(
    ({ tableState }) => {
        return (
            <TablePagination
                component="div"
                count={tableState.data.total}
                onPageChange={(_, newPage) => {
                    tableState.changePage(newPage);
                }}
                onRowsPerPageChange={(event) => {
                    tableState.changeRowsPerPage(
                        +event.target
                            .value as typeof tableState.filters.rowsPerPage
                    );
                }}
                page={tableState.filters.page}
                rowsPerPage={tableState.filters.rowsPerPage}
                rowsPerPageOptions={tableState.rowsPerPageOptions}
            />
        );
    }
);
