import { FC, Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    SportLeagueModel,
    SportLeagueUpdateModel,
} from '@/modules/common/api/api';
import {
    getFieldStateInputProps,
    noop,
    provide,
    useDependencies,
} from 'shared/src/utils';
import { SportLeagueEditStore } from '@/modules/sports/stores/sport-league-edit-store';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { X as XIcon } from '@phosphor-icons/react/dist/csr/X';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import { dayjs } from '@/lib/dayjs';
import { ImageUploader } from '@/modules/common/components/image-uploader/image-uploader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { EditFormItem } from '@/modules/common/components/edit-form-item/edit-form-item';

interface SportLeagueEditDialogProps {
    onClose: () => void;
    onApply: (updatedData: SportLeagueUpdateModel) => void;
    initialValue: SportLeagueModel;
}

export const SportLeagueEditDialog: FC<SportLeagueEditDialogProps> = provide([
    SportLeagueEditStore,
])(
    observer(({ onApply, onClose, initialValue }) => {
        const [
            {
                init,
                formState,
                removeLastUploadedLogo,
                uploadLogoByURL,
                uploadLogoDirectly,
                startLogoUploading,
                endLogoUploading,
                isUploadingLogo,
                getValidatedData,
            },
        ] = useDependencies(SportLeagueEditStore);

        useEffect(() => {
            init(initialValue);
        }, []);

        const { logo, name, seasonStartDate, seasonEndDate } = formState.$;

        const handleApply = () => {
            getValidatedData()
                .then((data) => {
                    onApply(data);
                })
                .catch(noop);
        };

        const handleCancel = () => {
            removeLastUploadedLogo();
            onClose();
        };

        return (
            <Fragment>
                <Dialog
                    open
                    onClose={handleCancel}
                    maxWidth="sm"
                    sx={{
                        '& .MuiDialog-container': {
                            justifyContent: 'flex-end',
                        },
                        '& .MuiDialog-paper': { height: '100%', width: '100%' },
                    }}
                >
                    <DialogTitle>Edit League</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <XIcon />
                    </IconButton>
                    <DialogContent>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Details
                        </Typography>
                        <Card component={Stack} divider={<Divider />}>
                            <EditFormItem name={'Logo'}>
                                <Stack direction={'row'} spacing={2}>
                                    {!!logo.value.url && (
                                        <Avatar
                                            variant={'rounded'}
                                            src={logo.value.url}
                                            sx={{
                                                '& img': {
                                                    objectFit: 'unset',
                                                },
                                            }}
                                        />
                                    )}
                                    <Button onClick={startLogoUploading}>
                                        Upload Logo
                                    </Button>
                                </Stack>
                            </EditFormItem>
                            <EditFormItem name={'League Name'}>
                                <OutlinedInput
                                    fullWidth
                                    placeholder={'League Name'}
                                    {...getFieldStateInputProps(name)}
                                />
                            </EditFormItem>
                            <EditFormItem name={'Start Date'}>
                                <DatePicker
                                    onChange={(value) => {
                                        seasonStartDate.onChange(
                                            value ? value.toDate() : undefined
                                        );
                                    }}
                                    sx={{ width: '100%' }}
                                    format="M/D/YY"
                                    value={
                                        seasonStartDate.value
                                            ? dayjs(seasonStartDate.value)
                                            : null
                                    }
                                />
                            </EditFormItem>
                            <EditFormItem name={'End Date'}>
                                <DatePicker
                                    onChange={(value) => {
                                        seasonEndDate.onChange(
                                            value ? value.toDate() : undefined
                                        );
                                    }}
                                    sx={{ width: '100%' }}
                                    format="M/D/YY"
                                    value={
                                        seasonEndDate.value
                                            ? dayjs(seasonEndDate.value)
                                            : null
                                    }
                                />
                            </EditFormItem>
                        </Card>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, py: 2 }}>
                        <Button
                            variant={'contained'}
                            disabled={formState.hasError}
                            onClick={handleApply}
                        >
                            {initialValue ? 'Save Changes' : 'Create'}
                        </Button>
                        <Button onClick={handleCancel} color={'secondary'}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                {isUploadingLogo && (
                    <ImageUploader
                        onDirectUpload={(file) =>
                            uploadLogoDirectly({
                                fileName: file.name,
                                data: file,
                            })
                        }
                        onURLUpload={uploadLogoByURL}
                        onClose={endLogoUploading}
                    />
                )}
            </Fragment>
        );
    })
);
