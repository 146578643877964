import { injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';

import { apiClient } from '@/modules/common/api/api-client';
import {
    JwtAuthResult,
    RefreshTokenReceiveModel,
} from '@/modules/common/api/api';

@injectable()
export class AuthStore {
    isAuthenticated = false;
    readyToRender = false;

    constructor() {
        makeAutoObservable(this);
    }

    authorize = async (jwtAuthResult?: JwtAuthResult) => {
        if (jwtAuthResult) {
            this.setAccessToken(jwtAuthResult.accessToken);

            // TODO: tmp
            if (jwtAuthResult.refreshToken) {
                this.setRefreshToken(jwtAuthResult.refreshToken.tokenString);
            }
        }

        runInAction(() => {
            this.readyToRender = false;
        });

        try {
            await apiClient.me();

            runInAction(() => {
                this.isAuthenticated = true;
            });
        } catch {
            // skip
        }

        runInAction(() => {
            this.readyToRender = true;
        });
    };

    unAuthorize = () => {
        this.isAuthenticated = false;
        this.setAccessToken('');
        this.setRefreshToken('');
    };

    handleUnauthorized = async () => {
        this.setAccessToken('');

        const refreshToken = localStorage.getItem('refreshToken');

        if (refreshToken) {
            const {
                accessToken: newAccessToken,
                refreshToken: newRefreshToken,
            } = await apiClient.refreshToken(
                new RefreshTokenReceiveModel({
                    tokenString: refreshToken,
                })
            );

            this.setAccessToken(newAccessToken);
            this.setRefreshToken(newRefreshToken?.tokenString);
        } else {
            throw new Error('Refresh Token not found');
        }
    };

    private setAccessToken = (token?: string) => {
        localStorage.setItem('token', token ?? '');
    };

    private setRefreshToken = (token?: string) => {
        localStorage.setItem('refreshToken', token ?? '');
    };
}
