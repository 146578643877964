import { FC } from 'react';
import { ChipProps } from '@mui/material';
import Chip from '@mui/material/Chip';
import { MatchOddStatus } from '@/modules/common/api/api';

interface GradeStatusProps extends Omit<ChipProps, 'color' | 'label'> {
    status: MatchOddStatus;
}

const mapStatusToColor: Record<MatchOddStatus, ChipProps['color']> = {
    [MatchOddStatus.Pushed]: 'warning',
    [MatchOddStatus.Won]: 'success',
    [MatchOddStatus.Lost]: 'error',
};

export const GradeStatus: FC<GradeStatusProps> = ({ status, ...props }) => {
    return (
        <Chip
            {...props}
            label={status as string}
            color={mapStatusToColor[status]}
            variant={'outlined'}
        />
    );
};
