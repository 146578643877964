import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';

interface BlockUserDialogProps {
    onConfirm: (note: string) => void;
    onClose: () => void;
}

export const BlockUserDialog: FC<BlockUserDialogProps> = observer(
    ({ onClose, onConfirm }) => {
        const [note, setNote] = useState('');

        const handleBlock = () => {
            onConfirm(note);
        };

        return (
            <Dialog open onClose={onClose} maxWidth={'xs'} fullWidth>
                <DialogTitle>Why are you blocking this user?</DialogTitle>
                <DialogContent>
                    <OutlinedInput
                        sx={{ mt: 1 }}
                        fullWidth
                        placeholder={'Add a note'}
                        value={note}
                        onChange={(event) => {
                            setNote(event.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button
                        variant={'contained'}
                        onClick={handleBlock}
                        disabled={!note.trim()}
                    >
                        Block
                    </Button>
                    <Button onClick={onClose} color={'secondary'}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);
