import { makeAutoObservable, runInAction, when } from 'mobx';
import { LoadStatus } from '../enums';
import axios from 'axios';

const rowsPerPageOptions = [5, 10, 25] as const;

export type TableStateData<T> = {
    items: T[];
    total: number;
};

export type TableStateDataGetter<T> = (
    filters: TableStateFilters,
    abortSignal: AbortSignal
) => Promise<TableStateData<T>>;

export type TableStateFilters = {
    searchTerm: string;
    page: number;
    rowsPerPage: (typeof rowsPerPageOptions)[number];
};

export class TableState<T> {
    filters: TableStateFilters = {
        page: 0,
        searchTerm: '',
        rowsPerPage: 5,
    };
    rowsPerPageOptions = rowsPerPageOptions;
    data: TableStateData<T> = {
        items: [],
        total: 0,
    };

    abortController = new AbortController();
    loadStatus = LoadStatus.None;
    getData;

    constructor(getData: TableStateDataGetter<T>) {
        this.getData = getData;
        makeAutoObservable(this);
    }

    fetchData = async (newFilters?: Partial<TableStateFilters>) => {
        this.abortController.abort();
        await when(() => this.loadStatus !== LoadStatus.Loading);
        this.abortController = new AbortController();

        runInAction(() => {
            this.loadStatus = LoadStatus.Loading;
        });

        if (newFilters) {
            runInAction(() => {
                this.filters = {
                    ...this.filters,
                    ...newFilters,
                };
            });
        }

        try {
            const data = await this.getData(
                this.filters,
                this.abortController.signal
            );

            runInAction(() => {
                this.data = data;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                runInAction(() => {
                    this.loadStatus = LoadStatus.Ok;
                });

                return;
            }

            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
                this.data = { items: [], total: 0 };
            });
        }
    };

    search = async (searchTerm: string) => {
        runInAction(() => {
            this.filters.page = 0;
            this.filters.searchTerm = searchTerm;
        });

        await this.fetchData();
    };

    changePage = async (page: typeof this.filters.page) => {
        runInAction(() => {
            this.filters.page = page;
        });

        await this.fetchData(this.filters);
    };

    changeRowsPerPage = async (
        rowsPerPage: typeof this.filters.rowsPerPage
    ) => {
        runInAction(() => {
            this.filters.page = 0;
            this.filters.rowsPerPage = rowsPerPage;
        });

        await this.fetchData(this.filters);
    };
}
