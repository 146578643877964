import { useEffect, useState } from 'react';

export const useOdometerNumber = (value: number = 0) => {
    const [finalValue, setFinalValue] = useState(value);

    useEffect(() => {
        const start = finalValue;
        const end = value;

        let current = start;
        const duration = 2000;
        const steps = 100;
        const stepInterval = duration / steps;
        const increment = (end - start) / steps;

        const interval = setInterval(() => {
            current += increment;

            setFinalValue(Math.round(current));

            if (
                (increment > 0 && current >= end) ||
                (increment < 0 && current <= end)
            ) {
                setFinalValue(end);
                clearInterval(interval);
            }
        }, stepInterval);

        return () => {
            clearInterval(interval);
        };
    }, [value]);

    return finalValue;
};
