import { FC } from 'react';
import { observer } from 'mobx-react';
import { DialogActions, DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { MatchOddStatus } from '@/modules/common/api/api';
import Stack from '@mui/material/Stack';

interface GradeBetDialogProps {
    onClose: () => void;
    onChange: (status: MatchOddStatus) => void;
    initialValue?: MatchOddStatus;
}

export const GradeBetDialog: FC<GradeBetDialogProps> = observer(
    ({ onChange, onClose, initialValue }) => {
        const [value, setValue] = React.useState(initialValue ?? null);

        const handleConfirm = () => {
            if (value === null) return;

            onChange(value);
        };

        return (
            <Dialog open={true} onClose={onClose}>
                <DialogTitle>
                    Grading is final and impacts user notifications — confirm
                    carefully.
                </DialogTitle>
                <DialogContent>
                    <Stack direction={'row'} justifyContent={'center'}>
                        <RadioGroup
                            name="matchOddStatus"
                            value={value}
                            onChange={(_, newValue) => {
                                setValue(newValue as MatchOddStatus);
                            }}
                            row
                        >
                            {Object.values(MatchOddStatus).map((status) => (
                                <FormControlLabel
                                    control={<Radio />}
                                    key={status}
                                    label={status}
                                    value={status}
                                />
                            ))}
                        </RadioGroup>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button
                        variant={'contained'}
                        disabled={value === null}
                        onClick={handleConfirm}
                    >
                        Confirm
                    </Button>
                    <Button color={'error'} onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);
