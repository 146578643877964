import { InputAdornment, TextFieldProps } from '@mui/material';
import { ChangeEvent, FC, useMemo } from 'react';
import debounce from 'lodash.debounce';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

interface DebouncedSearchProps extends Omit<TextFieldProps, 'variant'> {
    onSearch: (term: string) => void;
}

export const DebouncedSearch: FC<DebouncedSearchProps> = ({
    onSearch,
    ...props
}) => {
    const handleDebouncedSearch = useMemo(
        () =>
            debounce((event: ChangeEvent<HTMLInputElement>) => {
                onSearch(event.target.value);
            }, 1_000),
        [onSearch]
    );

    return (
        <TextField
            margin={'none'}
            onChange={handleDebouncedSearch}
            size={'small'}
            // label="Search"
            placeholder={'Search'}
            {...props}
            InputProps={{
                ...props.InputProps,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};
