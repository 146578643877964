import { observer } from 'mobx-react';
import { LoadStatus, useDependencies } from 'shared/src/utils';
import { BetGraderStore } from '@/modules/bet-grader/stores/bet-grader-store';
import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { dayjs } from '@/lib/dayjs';
import Button from '@mui/material/Button';
import { ColumnDef, DataTable } from '@/components/core/data-table';
import { BetOddModel } from '@/modules/common/api/api';
import IconButton from '@mui/material/IconButton';
import { PencilSimple } from '@phosphor-icons/react';
import { GradeStatus } from '@/modules/bet-grader/components/grade-status/grade-status';
import { BetInfoButton } from '@/modules/bet-grader/components/bet-info-button/bet-info-button';
import { GradeBetDialog } from '@/modules/bet-grader/components/grade-bet-dialog/grade-bet-dialog';
import { TableLoadingWrapper } from '@/modules/common/components/table-loading-wrapper/table-loading-wrapper';

export const BetGraderTable = observer(() => {
    const [
        {
            tableState,
            isPending,
            gradeBet,
            startGradeBet,
            endGradeBet,
            betIdToGrade,
        },
    ] = useDependencies(BetGraderStore);

    const columns = useMemo(() => {
        const result = [
            {
                name: 'League',
                width: '150px',
                formatter: (row) => row.league.name,
            },
            {
                formatter: (row) => row.match,
                name: 'Match Name',
                width: '250px',
            },
            {
                formatter: (row) => {
                    return dayjs(row.date).format('M/D/YY h:mm A');
                },
                name: 'MatchDate',
                width: '150px',
            },
            {
                formatter: (row) => row.market,
                name: 'Bet Market',
                width: '200px',
            },
            {
                formatter: (row) => row.name,
                name: 'Bet Name',
                width: '250px',
            },
            {
                formatter: (row) => row.gradingSystem,
                name: 'Grader',
                width: '120px',
            },
            {
                formatter: (row) => <BetInfoButton betId={row.id} />,
                name: 'Info',
                width: '120px',
            },
            {
                formatter: (row) => {
                    const handleGradeBet = () => startGradeBet(row.id);

                    if (row.status) {
                        return (
                            <Stack
                                direction={'row'}
                                spacing={1}
                                alignItems={'center'}
                                justifyContent={'end'}
                            >
                                <GradeStatus status={row.status} />
                                <IconButton
                                    color={'primary'}
                                    onClick={handleGradeBet}
                                >
                                    <PencilSimple />
                                </IconButton>
                            </Stack>
                        );
                    }

                    return (
                        <Button variant={'outlined'} onClick={handleGradeBet}>
                            Grade Bet
                        </Button>
                    );
                },
                name: 'Result',
                width: '150px',
                align: 'right',
            },
        ] satisfies ColumnDef<BetOddModel>[];

        if (isPending) {
            result.splice(5, 1);
        }

        return result;
    }, [isPending, startGradeBet]);

    const data = tableState.data.items;

    return (
        <TableLoadingWrapper
            isLoading={tableState.loadStatus === LoadStatus.Loading}
        >
            <DataTable<BetOddModel> columns={columns} rows={data} />
            {!data.length ? (
                <Box sx={{ p: 3 }}>
                    <Typography
                        color="text.secondary"
                        sx={{ textAlign: 'center' }}
                        variant="body2"
                    >
                        No bets found
                    </Typography>
                </Box>
            ) : null}
            {betIdToGrade !== undefined && (
                <GradeBetDialog
                    initialValue={
                        data.find((item) => item.id === betIdToGrade)?.status
                    }
                    onClose={endGradeBet}
                    onChange={(status) => {
                        gradeBet(betIdToGrade, status);
                    }}
                />
            )}
        </TableLoadingWrapper>
    );
});
