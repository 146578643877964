import { FC, Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import Dialog from '@mui/material/Dialog';
import {
    GameTemplatePublicModel,
    GameTemplateUpsertModel,
} from '@/modules/common/api/api';
import { DialogActions, DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { dayjs } from '@/lib/dayjs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
    getFieldStateInputProps,
    noop,
    provide,
    useDependencies,
} from 'shared/src/utils';
import { GameTemplateEditStore } from '@/modules/game-templates/stores/game-template-edit-store';
import IconButton from '@mui/material/IconButton';
import { X as XIcon } from '@phosphor-icons/react';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { ImageUploader } from '@/modules/common/components/image-uploader/image-uploader';
import Avatar from '@mui/material/Avatar';
import { ALL_OPTIONS_VALUE } from 'shared/src/utils/constants';
import { EditFormItem } from '@/modules/common/components/edit-form-item/edit-form-item';

interface GameTemplateEditDialogProps {
    onClose: () => void;
    onApply: (newGameTemplate: GameTemplateUpsertModel) => void;
    initialValue?: GameTemplatePublicModel;
}

export const GameTemplateEditDialog: FC<GameTemplateEditDialogProps> = provide([
    GameTemplateEditStore,
])(
    observer(({ onApply, onClose, initialValue }) => {
        const [
            {
                init,
                formState,
                availableLeagues,
                availableMatches,
                selectLeagues,
                selectMatch,
                isUploadingLogo,
                startLogoUploading,
                endLogoUploading,
                uploadLogoDirectly,
                uploadLogoByURL,
                removeLastUploadedLogo,
                getValidatedData,
            },
        ] = useDependencies(GameTemplateEditStore);

        useEffect(() => {
            init(initialValue);
        }, []);

        const { logo, name, leagueIds, matchId, startDate, endDate } =
            formState.$;

        const handleApply = () => {
            getValidatedData()
                .then((data) => {
                    if (data) {
                        onApply(data);
                    }
                })
                .catch(noop);
        };

        const handleCancel = () => {
            removeLastUploadedLogo();
            onClose();
        };

        return (
            <Fragment>
                <Dialog
                    open
                    onClose={handleCancel}
                    maxWidth="sm"
                    sx={{
                        '& .MuiDialog-container': {
                            justifyContent: 'flex-end',
                        },
                        '& .MuiDialog-paper': { height: '100%', width: '100%' },
                    }}
                >
                    <DialogTitle>
                        {initialValue ? 'Edit' : 'New'} Game Template
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <XIcon />
                    </IconButton>
                    <DialogContent>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Details
                        </Typography>
                        <Card component={Stack} divider={<Divider />}>
                            <EditFormItem name={'Logo'}>
                                <Stack direction={'row'} spacing={2}>
                                    {!!logo.value.url && (
                                        <Avatar
                                            variant={'rounded'}
                                            src={logo.value.url}
                                            sx={{
                                                '& img': {
                                                    objectFit: 'unset',
                                                },
                                            }}
                                        />
                                    )}
                                    <Button onClick={startLogoUploading}>
                                        Upload Logo
                                    </Button>
                                </Stack>
                            </EditFormItem>
                            <EditFormItem name={'Name'} required>
                                <OutlinedInput
                                    fullWidth
                                    placeholder={'Template Name'}
                                    {...getFieldStateInputProps(name)}
                                />
                            </EditFormItem>
                            <EditFormItem name={'Leagues'} required>
                                <Select
                                    multiple
                                    value={leagueIds.value}
                                    onChange={(e) => {
                                        selectLeagues(
                                            e.target.value as number[]
                                        );
                                    }}
                                    input={<OutlinedInput fullWidth />}
                                    MenuProps={{
                                        slotProps: {
                                            paper: {
                                                style: {
                                                    maxHeight: 210,
                                                    width: 350,
                                                },
                                                sx: {
                                                    '& ul': {
                                                        paddingRight:
                                                            '8px !important',
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                    renderValue={(selected) =>
                                        selected.length
                                            ? selected
                                                  .map(
                                                      (id) =>
                                                          availableLeagues.find(
                                                              (league) =>
                                                                  league.id ===
                                                                  id
                                                          )?.name ?? ''
                                                  )
                                                  .join(', ')
                                            : 'Select Leagues'
                                    }
                                >
                                    {availableLeagues.map(
                                        ({ name: leagueName, id }) => (
                                            <MenuItem key={id} value={id}>
                                                <Checkbox
                                                    checked={leagueIds.value.includes(
                                                        id
                                                    )}
                                                />
                                                <ListItemText
                                                    primary={leagueName}
                                                />
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </EditFormItem>
                            <EditFormItem name={'Match'}>
                                <Select
                                    disabled={leagueIds.value.length !== 1}
                                    value={matchId.value}
                                    onChange={(e) => {
                                        selectMatch(e.target.value as number);
                                    }}
                                    input={<OutlinedInput fullWidth />}
                                    MenuProps={{
                                        slotProps: {
                                            paper: {
                                                style: {
                                                    maxHeight: 210,
                                                    width: 350,
                                                },
                                                sx: {
                                                    '& ul': {
                                                        paddingRight:
                                                            '8px !important',
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value={ALL_OPTIONS_VALUE}>
                                        All Matches
                                    </MenuItem>
                                    {availableMatches.map(
                                        ({ name: matchName, id }) => (
                                            <MenuItem key={id} value={id}>
                                                {matchName}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </EditFormItem>
                            <EditFormItem name={'Start Date'} required>
                                <DateTimePicker
                                    onChange={(value) => {
                                        startDate.onChange(
                                            value ? value.toDate() : null
                                        );
                                    }}
                                    disabled={
                                        matchId.value !== ALL_OPTIONS_VALUE
                                    }
                                    sx={{ width: '100%' }}
                                    format="MMM D, YYYY hh:mm A"
                                    value={
                                        startDate.value
                                            ? dayjs(startDate.value)
                                            : null
                                    }
                                />
                            </EditFormItem>
                            <EditFormItem name={'End Date'} required>
                                <DateTimePicker
                                    onChange={(value) => {
                                        endDate.onChange(
                                            value ? value.toDate() : null
                                        );
                                    }}
                                    disabled={
                                        matchId.value !== ALL_OPTIONS_VALUE
                                    }
                                    sx={{ width: '100%' }}
                                    format="MMM D, YYYY hh:mm A"
                                    value={
                                        endDate.value
                                            ? dayjs(endDate.value)
                                            : null
                                    }
                                />
                            </EditFormItem>
                        </Card>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, py: 2 }}>
                        <Button
                            variant={'contained'}
                            disabled={formState.hasError}
                            onClick={handleApply}
                        >
                            {initialValue ? 'Save Changes' : 'Create'}
                        </Button>
                        <Button onClick={handleCancel} color={'secondary'}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                {isUploadingLogo && (
                    <ImageUploader
                        onDirectUpload={(file) =>
                            uploadLogoDirectly({
                                fileName: file.name,
                                data: file,
                            })
                        }
                        onURLUpload={uploadLogoByURL}
                        onClose={endLogoUploading}
                    />
                )}
            </Fragment>
        );
    })
);
