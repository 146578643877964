import {
    FilterPopover,
    useFilterContext,
} from '@/components/core/filter-button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Option } from '@/components/core/option';
import Button from '@mui/material/Button';
import { MatchOddStatus } from '@/modules/common/api/api';
import { useEffect, useState } from 'react';

export const ResultFilterPopover = () => {
    const {
        anchorEl,
        onApply,
        onClose,
        open,
        value: initialValue,
    } = useFilterContext();
    const [value, setValue] = useState<MatchOddStatus | ''>('');

    useEffect(() => {
        setValue((initialValue as MatchOddStatus | undefined) ?? '');
    }, [initialValue]);

    return (
        <FilterPopover
            anchorEl={anchorEl}
            onClose={onClose}
            open={open}
            title="Filter by Result"
        >
            <FormControl>
                <Select
                    onChange={(event) => {
                        setValue(event.target.value as typeof value);
                    }}
                    value={value}
                >
                    <Option value={''}>Select a result</Option>
                    {Object.values(MatchOddStatus).map((option) => (
                        <Option key={option} value={option}>
                            {option}
                        </Option>
                    ))}
                </Select>
            </FormControl>
            <Button
                onClick={() => {
                    onApply(value || undefined);
                }}
                variant="contained"
            >
                Apply
            </Button>
        </FilterPopover>
    );
};
