import { Box, SvgIcon, Typography, TypographyProps } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { AtmIcon } from '../icons/atm';
import { formatBigNumber, isBigNumber } from '../../utils/format-big-number';

export const Balance = ({
    amount = 0,
    typographyProps = {},
}: {
    amount?: number;
    typographyProps?: TypographyProps;
}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SvgIcon>
                <AtmIcon />
            </SvgIcon>
            <Tooltip
                arrow
                title={isBigNumber(amount) ? amount.toLocaleString() : ''}
                placement="top"
            >
                <Typography {...typographyProps}>
                    {formatBigNumber(amount)}
                </Typography>
            </Tooltip>
        </Box>
    );
};
