import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { LoadStatus, useDependencies } from 'shared/src/utils';
import { GameTemplatesStore } from '@/modules/game-templates/stores/game-templates-store';
import { dayjs } from '@/lib/dayjs';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { ColumnDef, DataTable } from '@/components/core/data-table';
import {
    GameTemplatePublicModel,
    GameTemplateStatus,
} from '@/modules/common/api/api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { GameTemplateEditDialog } from '@/modules/game-templates/components/game-template-edit-dialog/game-template-edit-dialog';
import Avatar from '@mui/material/Avatar';
import { useConfirm } from 'shared/src/hooks';
import { TableLoadingWrapper } from '@/modules/common/components/table-loading-wrapper/table-loading-wrapper';

export const GameTemplatesTable = observer(() => {
    const [
        {
            tableState,
            gameTemplateToEdit,
            changeTemplateStatus,
            endTemplateEditing,
            isEditing,
            saveTemplate,
            startTemplateEditing,
            deleteTemplate,
        },
    ] = useDependencies(GameTemplatesStore);

    const [Confirmation, handleDeleteTemplateWithConfirmation] =
        useConfirm(deleteTemplate);

    const columns = useMemo(() => {
        return [
            {
                name: 'Name',
                width: '150px',
                formatter: (row) => (
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{ alignItems: 'center' }}
                    >
                        {!!row.logo?.url && (
                            <Avatar
                                variant={'rounded'}
                                src={row.logo.url}
                                sx={{
                                    '& img': {
                                        objectFit: 'unset',
                                    },
                                }}
                            />
                        )}
                        <Typography variant={'inherit'}>{row.name}</Typography>
                    </Stack>
                ),
            },
            {
                formatter: (row) =>
                    row.leagues.map(({ name }) => name).join(', '),
                name: 'Leagues',
                width: '250px',
            },
            {
                formatter: (row) => row.match?.name ?? '-',
                name: 'Match',
                width: '250px',
            },
            {
                formatter: (row) => {
                    return row.startDate
                        ? dayjs(row.startDate).format('M/D/YY h:mm A')
                        : '-';
                },
                name: 'Start Date',
                width: '150px',
            },
            {
                formatter: (row) => {
                    return row.endDate
                        ? dayjs(row.endDate).format('M/D/YY h:mm A')
                        : '-';
                },
                name: 'End Date',
                width: '150px',
            },
            {
                formatter: (row) => {
                    return (
                        <Switch
                            onChange={(_, checked) => {
                                changeTemplateStatus(
                                    row.id,
                                    checked
                                        ? GameTemplateStatus.Active
                                        : GameTemplateStatus.Inactive
                                );
                            }}
                            checked={row.status === GameTemplateStatus.Active}
                        />
                    );
                },
                name: 'Live',
                width: '50px',
            },
            {
                formatter: (row) => {
                    return (
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                            justifyContent={'end'}
                        >
                            <IconButton
                                color={'error'}
                                onClick={() =>
                                    handleDeleteTemplateWithConfirmation(row.id)
                                }
                            >
                                <Trash weight={'bold'} />
                            </IconButton>
                            <IconButton
                                color={'primary'}
                                onClick={() => startTemplateEditing(row)}
                            >
                                <PencilSimple weight={'bold'} />
                            </IconButton>
                        </Stack>
                    );
                },
                name: 'Actions',
                width: '100px',
                align: 'right',
            },
        ] satisfies ColumnDef<GameTemplatePublicModel>[];
    }, [changeTemplateStatus, startTemplateEditing]);

    const data = tableState.data.items;

    return (
        <TableLoadingWrapper
            isLoading={tableState.loadStatus === LoadStatus.Loading}
        >
            <DataTable<GameTemplatePublicModel> columns={columns} rows={data} />
            {!data.length ? (
                <Box sx={{ p: 3 }}>
                    <Typography
                        color="text.secondary"
                        sx={{ textAlign: 'center' }}
                        variant="body2"
                    >
                        No templates found
                    </Typography>
                </Box>
            ) : null}
            {isEditing && (
                <GameTemplateEditDialog
                    initialValue={gameTemplateToEdit}
                    onClose={endTemplateEditing}
                    onApply={saveTemplate}
                />
            )}
            <Confirmation
                title={''}
                primaryActionName={'Delete'}
                message={'Are you sure you want to delete this game template?'}
            />
        </TableLoadingWrapper>
    );
});
