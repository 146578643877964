import { AuthStrategy } from '@/lib/auth/strategy';
import { getSiteURL } from '@/lib/get-site-url';
import { LogLevel } from '@/lib/logger';
import type { ColorScheme, PrimaryColor } from '@/styles/theme/types';

export interface Config {
    site: {
        name: string;
        description: string;
        language: string;
        colorScheme: ColorScheme;
        themeColor: string;
        primaryColor: PrimaryColor;
        url: string;
        version: string;
    };
    logLevel: keyof typeof LogLevel;
    auth: { strategy: keyof typeof AuthStrategy };
    auth0: { domain?: string; clientId?: string };
    cognito: {
        identityPoolId?: string;
        userPoolClientId?: string;
        userPoolId?: string;
    };
    firebase: {
        apiKey?: string;
        appId?: string;
        authDomain?: string;
        messagingSenderId?: string;
        projectId?: string;
        storageBucket?: string;
    };
    supabase: { url?: string; anonKey?: string };
    mapbox: { apiKey?: string };
    gtm?: { id?: string };
}

export const config = {
    site: {
        name: 'Admin Portal - BalanceBet',
        description: '',
        language: 'en',
        colorScheme: 'light',
        themeColor: '#090a0b',
        primaryColor: 'neonBlue',
        url: getSiteURL(),
        version: process.env.REACT_APP_SITE_VERSION || '0.0.0',
    },
    logLevel:
        (process.env.REACT_APP_LOG_LEVEL as keyof typeof LogLevel) ||
        LogLevel.ALL,
    auth: {
        strategy:
            (process.env
                .REACT_APP_AUTH_STRATEGY as keyof typeof AuthStrategy) ||
            AuthStrategy.CUSTOM,
    },
    auth0: {
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    },
    cognito: {
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    },
    firebase: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    },
    supabase: {
        url: process.env.REACT_APP_SUPABASE_URL,
        anonKey: process.env.REACT_APP_SUPABASE_ANON_KEY,
    },
    mapbox: { apiKey: process.env.REACT_APP_MAPBOX_API_KEY },
    gtm: { id: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID },
} satisfies Config;
