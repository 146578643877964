import { observer } from 'mobx-react';
import { LoadStatus, provide, useDependencies } from 'shared/src/utils';
import { DashboardStore } from '@/modules/dashboard/stores/dashboard-store';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

export const DashboardPage = provide([DashboardStore])(
    observer(() => {
        const [{ onlinePlayersCount, loadStatus, init }] =
            useDependencies(DashboardStore);

        useEffect(() => {
            init();
        }, []);

        return (
            <Box
                sx={{
                    maxWidth: 'var(--Content-maxWidth)',
                    m: 'var(--Content-margin)',
                    p: 'var(--Content-padding)',
                    width: 'var(--Content-width)',
                }}
            >
                <Typography variant={'h4'}>
                    Online Players:{' '}
                    {loadStatus === LoadStatus.Loading
                        ? 'Loading...'
                        : onlinePlayersCount}
                </Typography>
            </Box>
        );
    })
);
