import { FieldState } from 'formstate';
import { ChangeEvent } from 'react';

export const getFieldStateInputProps = (fieldState: FieldState<string>) => {
    return {
        value: fieldState.value,
        error: fieldState.hasError,
        onChange: (
            event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
            fieldState.onChange(event.target.value);
        },
        onBlur: fieldState.enableAutoValidationAndValidate,
    };
};
