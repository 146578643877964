import { FC, Fragment, PropsWithChildren } from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import Box from '@mui/material/Box';
import { Navigation } from '@/modules/app/components/navigation';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
    // const { settings } = useSettings();

    return (
        <Fragment>
            <GlobalStyles styles={{ body: { '--MainNav-zIndex': 1000 } }} />
            <Box
                sx={{
                    bgcolor: 'var(--mui-palette-background-default)',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    minHeight: '100%',
                }}
            >
                <Navigation />
                <Box
                    component="main"
                    sx={{
                        '--Content-margin': '0 auto',
                        '--Content-paddingX': '24px',
                        '--Content-paddingY': '24px',
                        '--Content-padding':
                            'var(--Content-paddingY) var(--Content-paddingX)',
                        '--Content-width': '100%',
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Fragment>
    );
};
