import { injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';
import { apiClient } from '@/modules/common/api/api-client';
import {
    FileParameter,
    IdNameModel,
    ImageUrlUploadModel,
    SportTeamModel,
} from '@/modules/common/api/api';
import { TableState, TableStateDataGetter } from 'shared/src/utils';

export enum LogoFilterEnum {
    NoLogo = 'No Logo',
    Uploaded = 'Uploaded',
}

@injectable()
export class TeamsStore {
    tableState: TableState<SportTeamModel>;
    availableLeagues: IdNameModel[] = [];
    logoFilter?: LogoFilterEnum;
    selectedLeague: (typeof this.availableLeagues)[number]['id'] | null = null;

    teamIdToUploadLogo?: SportTeamModel['id'];

    constructor() {
        this.tableState = new TableState(this.getData);
        makeAutoObservable(this);
    }

    applyLogoFilter = async (logoFilter: typeof this.logoFilter) => {
        runInAction(() => {
            this.logoFilter = logoFilter;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    startLogoUploading = (id: typeof this.teamIdToUploadLogo) => {
        this.teamIdToUploadLogo = id;
    };

    endLogoUploading = () => {
        this.teamIdToUploadLogo = undefined;
    };

    uploadLogoDirectly = async (
        id: SportTeamModel['id'],
        file: FileParameter
    ) => {
        try {
            await apiClient.updateSportTeamLogoByFile(id, file);
            this.endLogoUploading();
            await this.tableState.fetchData();
        } catch (err) {
            console.error(err);
        }
    };

    updateLogoURL = async (id: SportTeamModel['id'], url: string) => {
        try {
            await apiClient.updateSportTeamLogoByUrl(
                id,
                new ImageUrlUploadModel({ url })
            );
            this.endLogoUploading();
            await this.tableState.fetchData();
        } catch (err) {
            console.error(err);
        }
    };

    init = async () => {
        try {
            const { items: availableLeagues } = await apiClient.getSportLeagues(
                undefined,
                undefined,
                undefined
            );

            runInAction(() => {
                this.availableLeagues = availableLeagues;
            });

            await this.tableState.fetchData();
        } catch {
            //
        }
    };

    changeLeague = async (league: typeof this.selectedLeague) => {
        runInAction(() => {
            this.selectedLeague = league;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    getData: TableStateDataGetter<SportTeamModel> = async (
        filters,
        abortSignal
    ) => {
        const data = await apiClient.sportTeams(
            this.selectedLeague ?? undefined,
            filters.searchTerm,
            this.logoFilter !== undefined
                ? this.logoFilter === LogoFilterEnum.Uploaded
                : undefined,
            undefined,
            Math.round(filters.page * filters.rowsPerPage),
            filters.rowsPerPage,
            abortSignal
        );

        return {
            items: data.items ?? [],
            total: data.totalAmount,
        };
    };
}
