import { injectable } from 'inversify';
import {
    LoadStatus,
    noop,
    TableState,
    TableStateDataGetter,
} from 'shared/src/utils';
import { makeAutoObservable, runInAction } from 'mobx';
import {
    GameTemplatePublicModel,
    GameTemplateStatus,
    GameTemplateUpsertModel,
} from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';

@injectable()
export class GameTemplatesStore {
    tableState: TableState<GameTemplatePublicModel>;
    gameTemplateToEdit?: GameTemplatePublicModel;
    isEditing = false;

    constructor() {
        this.tableState = new TableState(this.getData);
        makeAutoObservable(this);
    }

    init = async () => {
        try {
            await this.tableState.fetchData();
        } catch {
            //
        }
    };

    deleteTemplate = async (id: GameTemplatePublicModel['id']) => {
        try {
            await apiClient.gameTemplatesDELETE(id);
            this.tableState.fetchData().catch(noop);
        } catch {
            //
        }
    };

    startTemplateCreating = () => {
        this.isEditing = true;
    };

    startTemplateEditing = (template: GameTemplatePublicModel) => {
        this.isEditing = true;
        this.gameTemplateToEdit = template;
    };

    endTemplateEditing = () => {
        this.isEditing = false;
        this.gameTemplateToEdit = undefined;
    };

    saveTemplate = async (payload: GameTemplateUpsertModel) => {
        try {
            if (this.gameTemplateToEdit) {
                await apiClient.gameTemplatesPUT(
                    this.gameTemplateToEdit.id,
                    payload
                );
            } else {
                await apiClient.gameTemplatesPOST(payload);
            }

            this.tableState.fetchData().catch(noop);

            this.endTemplateEditing();
        } catch {
            // skip
        }
    };

    changeTemplateStatus = async (
        id: GameTemplatePublicModel['id'],
        newStatus: GameTemplateStatus
    ) => {
        try {
            await apiClient.status(id, newStatus);

            const editedDataItemIndex = this.tableState.data.items.findIndex(
                (item) => item.id === id
            );

            if (editedDataItemIndex !== -1) {
                runInAction(() => {
                    this.tableState.data.items[editedDataItemIndex] =
                        new GameTemplatePublicModel({
                            ...this.tableState.data.items[editedDataItemIndex],
                            status: newStatus,
                        });
                });
            }
        } catch {
            // skip
        }
    };

    getData: TableStateDataGetter<GameTemplatePublicModel> = async (
        filters,
        abortSignal
    ) => {
        const data = await apiClient.gameTemplatesGET(
            filters.searchTerm,
            undefined,
            Math.round(filters.page * filters.rowsPerPage),
            filters.rowsPerPage,
            abortSignal
        );

        return {
            items: data.items ?? [],
            total: data.totalAmount,
        };
    };
}
