import { observer } from 'mobx-react';
import { provide, useDependencies } from 'shared/src/utils';
import { GameLeaguesStore } from '@/modules/game-leagues/stores/game-leagues-store';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useEffect } from 'react';
import { GameLeaguesPagination } from '@/modules/game-leagues/components/game-leagues-content/game-leagues-pagination';
import { GameLeaguesFilters } from '@/modules/game-leagues/components/game-leagues-content/game-leagues-filters';
import { GameLeaguesTable } from '@/modules/game-leagues/components/game-leagues-content/game-leagues-table';

export const GameLeaguesPage = provide([GameLeaguesStore])(
    observer(() => {
        const [{ init }] = useDependencies(GameLeaguesStore);

        useEffect(() => {
            init();
        }, []);

        return (
            <Box
                sx={{
                    maxWidth: 'var(--Content-maxWidth)',
                    m: 'var(--Content-margin)',
                    p: 'var(--Content-padding)',
                    width: 'var(--Content-width)',
                }}
            >
                <Card>
                    <Typography variant={'h5'} p={3}>
                        Leagues
                    </Typography>
                    <GameLeaguesFilters />
                    <Divider />
                    <Box sx={{ overflowX: 'auto' }}>
                        <GameLeaguesTable />
                    </Box>
                    <Divider />
                    <GameLeaguesPagination />
                </Card>
            </Box>
        );
    })
);
