import { observer } from 'mobx-react-lite';
import { provide, useDependencies } from 'shared/src/utils';
import { AuthStore } from '@/modules/app/stores/auth-store';
import {
    createBrowserRouter,
    Navigate,
    Outlet,
    RouterProvider,
} from 'react-router-dom';
import { Root } from '@/root';
import { ScrollRestoration } from '@/components/core/scroll-restoration';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useAuthInterceptors } from '@/modules/common/hooks/use-auth-interceptors';
import CircularProgress from '@mui/material/CircularProgress';
import { SignInPage } from '@/modules/auth/sign-in/components/sign-in-page';
import { Layout } from '@/modules/app/components/layout';
import { TeamsPage } from '@/modules/sports/components/teams/teams-page';
import { PendingBetsPage } from '@/modules/bet-grader/components/pending-bets-page';
import { HistoricalBetsPage } from '@/modules/bet-grader/components/historical-bets-page';
import { PlayersPage } from '@/modules/sports/components/players/players-page';
import { GameTemplatesPage } from '@/modules/game-templates/components/game-templates-content/game-templates-page';
import { GameLeaguesPage } from '@/modules/game-leagues/components/game-leagues-content/game-leagues-page';
import { SportLeaguesPage } from '@/modules/sports/components/leagues/sport-leagues-page';
import { UsersPage } from '@/modules/users/components/users-page';
import { DashboardPage } from '@/modules/dashboard/components/dashboard-page';

export const App = provide([AuthStore])(
    observer(() => {
        const [{ authorize, readyToRender, isAuthenticated }] =
            useDependencies(AuthStore);

        useAuthInterceptors();

        useEffect(() => {
            authorize();
        }, []);

        if (!readyToRender) {
            return (
                <Box
                    height={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <CircularProgress />
                </Box>
            );
        }

        const returnURL = location.pathname + location.search + location.hash;

        const redirectionURLSearchParams = new URLSearchParams();
        redirectionURLSearchParams.set(
            'returnURL',
            encodeURIComponent(returnURL)
        );

        const routes = createBrowserRouter([
            {
                path: '/',
                element: (
                    <Root>
                        <ScrollRestoration />
                        {isAuthenticated ? (
                            <Layout>
                                <Outlet />
                            </Layout>
                        ) : (
                            <Outlet />
                        )}
                    </Root>
                ),
                children: isAuthenticated
                    ? [
                          {
                              path: '',
                              element: <Navigate to={'sports'} />,
                          },
                          {
                              path: '/dashboard',
                              element: <Outlet />,
                              children: [
                                  {
                                      path: '',
                                      element: <DashboardPage />,
                                  },
                                  {
                                      path: '*',
                                      element: <Navigate to={'teams'} />,
                                  },
                              ],
                          },
                          {
                              path: '/sports',
                              element: <Outlet />,
                              children: [
                                  {
                                      path: '',
                                      element: <Navigate to={'teams'} />,
                                  },
                                  {
                                      path: 'teams',
                                      element: <TeamsPage />,
                                  },
                                  {
                                      path: 'players',
                                      element: <PlayersPage />,
                                  },
                                  {
                                      path: 'leagues',
                                      element: <SportLeaguesPage />,
                                  },
                                  {
                                      path: '*',
                                      element: <Navigate to={'teams'} />,
                                  },
                              ],
                          },
                          {
                              path: '/bet-grader',
                              element: <Outlet />,
                              children: [
                                  {
                                      path: '',
                                      element: <Navigate to={'pending'} />,
                                  },
                                  {
                                      path: 'pending',
                                      element: <PendingBetsPage />,
                                  },
                                  {
                                      path: 'historical',
                                      element: <HistoricalBetsPage />,
                                  },
                                  {
                                      path: '*',
                                      element: <Navigate to={'pending'} />,
                                  },
                              ],
                          },
                          {
                              path: '/game-templates',
                              element: <Outlet />,
                              children: [
                                  {
                                      path: '',
                                      element: <GameTemplatesPage />,
                                  },
                                  {
                                      path: '*',
                                      element: (
                                          <Navigate to={'game-templates'} />
                                      ),
                                  },
                              ],
                          },
                          {
                              path: '/game-leagues',
                              element: <Outlet />,
                              children: [
                                  {
                                      path: '',
                                      element: <GameLeaguesPage />,
                                  },
                                  {
                                      path: '*',
                                      element: <Navigate to={'game-leagues'} />,
                                  },
                              ],
                          },
                          {
                              path: '/users',
                              element: <Outlet />,
                              children: [
                                  {
                                      path: '',
                                      element: <UsersPage />,
                                  },
                                  {
                                      path: '*',
                                      element: <Navigate to={'users'} />,
                                  },
                              ],
                          },
                          {
                              path: '*',
                              element: <Navigate to={'dashboard'} />,
                          },
                      ]
                    : [
                          {
                              path: '',
                              element: <Navigate to={'sign-in'} replace />,
                          },
                          {
                              path: 'sign-in',
                              element: <SignInPage />,
                          },
                          {
                              path: 'sign-up',
                              element: <Box>Sign up</Box>,
                          },
                          {
                              path: '*',
                              element: (
                                  <Navigate
                                      to={{
                                          pathname: 'sign-in',
                                          search: redirectionURLSearchParams.toString(),
                                      }}
                                      replace
                                  />
                              ),
                          },
                      ],
            },
        ]);

        return <RouterProvider router={routes} />;
    })
);
