import { FC, ReactElement, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Check, Copy } from '@phosphor-icons/react';

type PopoverInfoItemProps = { field: string } & (
    | {
          value: string | number;
          copiable?: undefined | true;
      }
    | {
          value: ReactElement;
          copiable?: undefined;
      }
);

export const PopoverInfoItem: FC<PopoverInfoItemProps> = ({
    field,
    value,
    copiable,
}) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        if (isCopied) return;

        navigator.clipboard?.writeText(String(value)).then(() => {
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        });
    };

    return (
        <Stack
            direction="row"
            spacing={3}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <Typography
                whiteSpace={'nowrap'}
                variant="body2"
                color={'textSecondary'}
            >
                {field}
            </Typography>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                {typeof value === 'string' || typeof value === 'number' ? (
                    <Typography variant="body2">{value}</Typography>
                ) : (
                    value
                )}
                {copiable ? (
                    <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                        <IconButton onClick={handleCopy}>
                            {isCopied ? <Check /> : <Copy />}
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Stack>
        </Stack>
    );
};
