import {createContext} from 'react';

import {Container, interfaces} from 'inversify';

export type Entry<T> = interfaces.Newable<T>;

export type Entries<T> = {
    [P in keyof T]: Entry<T[P]>
}

export const rootContainer = new Container();

export const ContainerContext = createContext(rootContainer);
