import { provide, useDependencies } from 'shared/src/utils';
import { GameTemplatesStore } from '@/modules/game-templates/stores/game-templates-store';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import { GameTemplatesTable } from '@/modules/game-templates/components/game-templates-content/game-templates-table';
import { GameTemplatesPagination } from '@/modules/game-templates/components/game-templates-content/game-templates-pagination';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { PlusCircle } from '@phosphor-icons/react';

export const GameTemplatesPage = provide([GameTemplatesStore])(
    observer(() => {
        const [{ init, startTemplateCreating }] =
            useDependencies(GameTemplatesStore);

        useEffect(() => {
            init();
        }, []);

        return (
            <Box
                sx={{
                    maxWidth: 'var(--Content-maxWidth)',
                    m: 'var(--Content-margin)',
                    p: 'var(--Content-padding)',
                    width: 'var(--Content-width)',
                }}
            >
                <Card>
                    <Stack
                        p={3}
                        justifyContent={'space-between'}
                        direction={'row'}
                    >
                        <Typography variant={'h5'}>Game Templates</Typography>
                        <Button
                            variant={'contained'}
                            endIcon={<PlusCircle weight={'bold'} />}
                            onClick={startTemplateCreating}
                        >
                            Create Template
                        </Button>
                    </Stack>
                    <Divider />
                    <Box sx={{ overflowX: 'auto' }}>
                        <GameTemplatesTable />
                    </Box>
                    <Divider />
                    <GameTemplatesPagination />
                </Card>
            </Box>
        );
    })
);
