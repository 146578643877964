import type { SVGProps } from 'react';

export const AtmIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        viewBox="0 0 20 16"
        fill="none"
    >
        <path
            d="M11 10H8C7.71667 10 7.47917 10.0958 7.2875 10.2875C7.09583 10.4792 7 10.7167 7 11C7 11.2833 7.09583 11.5208 7.2875 11.7125C7.47917 11.9042 7.71667 12 8 12H9C9 12.2833 9.09583 12.5208 9.2875 12.7125C9.47917 12.9042 9.71667 13 10 13C10.2667 13 10.4542 12.8792 10.5625 12.6375C10.6708 12.3958 10.8167 12.1833 11 12H12C12.2833 12 12.5208 11.9042 12.7125 11.7125C12.9042 11.5208 13 11.2833 13 11V8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7H9V6H12C12.2833 6 12.5208 5.90417 12.7125 5.7125C12.9042 5.52083 13 5.28333 13 5C13 4.71667 12.9042 4.47917 12.7125 4.2875C12.5208 4.09583 12.2833 4 12 4H11C11 3.71667 10.9042 3.47917 10.7125 3.2875C10.5208 3.09583 10.2833 3 10 3C9.73333 3 9.54583 3.12083 9.4375 3.3625C9.32917 3.60417 9.18333 3.81667 9 4H8C7.71667 4 7.47917 4.09583 7.2875 4.2875C7.09583 4.47917 7 4.71667 7 5V8C7 8.28333 7.09583 8.52083 7.2875 8.7125C7.47917 8.90417 7.71667 9 8 9H11V10ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2Z"
            fill="#F3B61F"
        />
    </svg>
);
