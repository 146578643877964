import { observer } from 'mobx-react';
import { useDependencies } from 'shared/src/utils';
import { GameTemplatesStore } from '@/modules/game-templates/stores/game-templates-store';
import { CommonTablePagination } from '@/modules/common/components/common-table-pagination/common-table-pagination';

export const GameTemplatesPagination = observer(() => {
    const [{ tableState }] = useDependencies(GameTemplatesStore);

    return <CommonTablePagination tableState={tableState} />;
});
