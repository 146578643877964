import { useState, useCallback, useMemo, ComponentType, FC } from 'react';
import {
    Dialog,
    DialogActions,
    DialogProps,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { X as XIcon } from '@phosphor-icons/react';

export interface ConfirmationProps {
    closable?: boolean;
    title?: string;
    message?: string;
    onConfirm(): void;
    onCancel(): void;
    primaryActionName?: string;
    secondaryActionName?: string;
}

type DefaultConfirmationProps = ConfirmationProps &
    Partial<Omit<DialogProps, 'onClose' | 'open'>>;

const DefaultConfirmation: FC<DefaultConfirmationProps> = ({
    title = 'Are you sure?',
    message = '',
    onConfirm,
    onCancel,
    primaryActionName = 'Confirm',
    secondaryActionName = 'Cancel',
    closable,
    ...props
}) => (
    <Dialog open onClose={onCancel} title={title} {...props}>
        <DialogTitle>{title}</DialogTitle>
        {closable && (
            <IconButton
                aria-label="close"
                onClick={onCancel}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <XIcon />
            </IconButton>
        )}

        <DialogContent>{message}</DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
            <Button variant={'contained'} onClick={onConfirm}>
                {primaryActionName}
            </Button>
            <Button onClick={onCancel} color={'secondary'}>
                {secondaryActionName}
            </Button>
        </DialogActions>
    </Dialog>
);

interface Defer {
    confirm(): void;
    cancel(): void;
}

export type ConfirmProps<P extends ConfirmationProps> = {
    when?: boolean;
    component?: ComponentType<P>;
} & Omit<P, 'onConfirm' | 'onCancel'>;

export function useConfirm<T extends (...args: any[]) => any>(
    onConfirm: T,
    onCancel?: T
) {
    const [defer, setDefer] = useState<Defer | undefined>(undefined);

    const handler = useCallback(
        async (...args: any[]) => {
            let isConfirmed: boolean;

            try {
                isConfirmed = await new Promise((resolve, reject) => {
                    setDefer({
                        confirm: () => resolve(true),
                        cancel: reject,
                    });
                });
            } catch {
                return;
            } finally {
                setDefer(undefined);
            }

            if (isConfirmed) {
                onConfirm(...args);
            } else if (onCancel) {
                onCancel(...args);
            }
        },
        [onConfirm, onCancel]
    );

    const Confirm = useMemo(
        () =>
            <P extends ConfirmationProps = DefaultConfirmationProps>({
                when = true,
                component:
                    Confirmation = DefaultConfirmation as ComponentType<P>,
                ...props
            }: ConfirmProps<P>) => {
                if (!defer) {
                    return null;
                }

                if (!when) {
                    defer.confirm();
                    return null;
                }

                return (
                    <Confirmation
                        {...(props as P)}
                        onConfirm={defer.confirm}
                        onCancel={defer.cancel}
                    />
                );
            },
        [defer]
    );

    return [Confirm, handler] as [typeof Confirm, T];
}
